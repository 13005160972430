import { GoogleMap as GoogleMaps, useLoadScript } from "@react-google-maps/api";
import React from "react";
import { GOOGLE_MAPS_API_KEY } from "../../constants";
import mapStyles from "../Main/Map/mapStyles";

const libraries = ["places"];
const GoogleMap = ({
  height,
  zoom,
  center,
  noInteraction,
  onClick,
  children,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return null;
  return (
    <GoogleMaps
      mapContainerStyle={{
        width: "100%",
        height: height ?? "100vh",
      }}
      options={{
        styles: mapStyles,
        disableDefaultUI: true,
        draggable: noInteraction ? false : true,
        zoomControl: noInteraction ? false : true,
        scrollwheel: noInteraction ? false : true,
        disableDoubleClickZoom: noInteraction ? false : true,
      }}
      center={center || { lat: 55.8502712, lng: 12.9147853 }}
      zoom={zoom ?? 11}
      onClick={onClick}
    >
      {children}
    </GoogleMaps>
  );
};

export default GoogleMap;
