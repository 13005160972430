import React, { useRef, useState } from "react";
import arrow from "../../assets/media/arrow_down.svg";
import capitalize from "../../utils/capitalize";
import useOnClickOutside from "../../utils/useOnClickOutside";
import {
  RoleSelectorDropdown,
  RoleSelectorDropdownItem,
  RoleSelectorWrapper,
} from "./styles";

const RoleSelector = ({ role, userId, locationId, changeRole }) => {
  const [currentRole, setCurrentRole] = useState(role);
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleRoleChange = (role) => {
    const newRole = role.toLowerCase();
    // Don't continue when the incoming role is same as current role
    if (newRole === currentRole) return;
    // Change role instantly in current state
    setCurrentRole(newRole);
    // Change the role in the parent component
    changeRole(userId, locationId, newRole);
  };

  return (
    <RoleSelectorWrapper ref={ref} onClick={() => setOpen((p) => !p)}>
      {capitalize(currentRole === "Employee" ? "Coworker" : currentRole)}
      <span>
        <img src={arrow} alt="Change" />
      </span>
      {open && (
        <RoleSelectorDropdown>
          {roles.map((thisRole, i) => (
            <RoleSelectorDropdownItem
              key={i}
              isCurrent={capitalize(currentRole) === thisRole ? 1 : null}
              onClick={() => handleRoleChange(thisRole)}
            >
              {thisRole}
            </RoleSelectorDropdownItem>
          ))}
        </RoleSelectorDropdown>
      )}
    </RoleSelectorWrapper>
  );
};

const roles = ["Coworker", "Supervisor", "Admin"];

export default RoleSelector;
