import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  margin: 4rem 0;
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    display: inline-flex;
    flex-direction: row;
    margin-top: 6rem;
  }
`;

export const PlanOuter = styled.div`
  position: relative;
  width: 100%;
`;

export const PlanWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  @media (min-width: 768px) {
    margin: unset;
  }
`;

export const CardWrapper = styled.div`
  background: #eaeaea;
  padding: 1rem;
  margin-right: 0;
  margin-bottom: 3rem;
  width: 100%;
  height: 450px;
  background: #eaeaea;
  display: flex;
  flex-direction: column;
  h2 {
    color: #2c2c2c;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 1.25rem;
    margin-top: 1rem;
    font-family: "Euclid-Bold";
  }
  h3 {
    line-height: 1.3;
    color: #2c2c2c;
    font-size: 16px;
    font-family: "Euclid-Regular";
  }
  p {
    line-height: 1.4;
    color: #8c8c8c;
    font-size: 14px;
    font-family: "Euclid-Regular";
  }
  @media only screen and (min-width: 768px) {
    padding: 1.5rem;
    width: 320px;
    margin-right: 6rem;
  }
`;

export const Price = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  h4 {
    color: #2c2c2c;
    font-size: 47px;
    font-weight: 700;
    font-family: "Euclid-Bold";
    span {
      display: inline-flex;
      margin-left: 0.35rem;
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 400;
      font-family: "Euclid-Regular";
    }
  }
  p {
    font-family: "Euclid-Regular";
    margin-top: 0.5rem;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
  button {
    background: #2455c2;
    border: none;
    color: #fff;
    font-family: "Euclid-Regular";
    display: flex;
    width: 150px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1.25rem;
    transition: 0.25s all ease;
    cursor: pointer;
    &:hover {
      background: #3c6fe1;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  ${({ isCurrentPlan }) =>
    isCurrentPlan &&
    css`
      button {
        background: #d9d9d9;
        color: #2c2c2c;
        &:hover {
          background: #d9d9d9;
        }
      }
    `}
`;
