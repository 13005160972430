import styled from "styled-components";
import date from "../../../../assets/media/date.svg";

export const Wrapper = styled.div`
  max-width: 264px;
  position: relative;
  .react-datepicker-wrapper {
    width: 100%;
  }
  input {
    font-family: "Euclid-Regular", sans-serif;
    font-size: 16px;
    padding: 0.75rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #8492a6;
    &::placeholder {
      color: #47525e;
      font-family: "Euclid-Regular";
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    width: 24px;
    height: 24px;
    pointer-events: none;
    user-select: none;
    background-image: url(${date});
    background-repeat: no-repeat;
  }
`;
