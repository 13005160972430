import React from "react";
import Footer from "../Auth/Footer";
import Header from "../Main/Header";
import { Wrapper, Title } from "./styles";

const Policy = () => {
  return (
    <>
      <Header variant="secondary" title="Privacy Policy" />
      <Wrapper>
        <Title>Content</Title>
        <p>
          These Terms of Use constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity (“you”) andPAW Pals
          At Work AB("Company", “we”, “us”, or “our”), concerning your access to
          and use of thejobpalapp.comwebsite as well as any other media form,
          media channel, mobile website or mobile application related, linked,
          or otherwise connected thereto (collectively, the “Site”). You agree
          that by accessing the Site, you have read, understood, and agreed to
          be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF
          THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
          SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </p>
        <Title>Content</Title>
        <p>
          These Terms of Use constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity (“you”) andPAW Pals
          At Work AB("Company", “we”, “us”, or “our”), concerning your access to
          and use of thejobpalapp.comwebsite as well as any other media form,
          media channel, mobile website or mobile application related, linked,
          or otherwise connected thereto (collectively, the “Site”). You agree
          that by accessing the Site, you have read, understood, and agreed to
          be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF
          THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
          SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </p>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Policy;
