export default function checkSubscription(subscription) {
  if (!subscription) return false;
  let result = false;
  if (
    subscription?.status !== "Cancelled" &&
    subscription?.status !== "Expired"
  )
    result = true;
  return result;
}
