import { Marker } from "@react-google-maps/api";
import Cookies from "universal-cookie";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import arrow from "../../../assets/media/arrow_right.svg";
import close from "../../../assets/media/close.png";
import dotMarker from "../../../assets/media/dot-marker.svg";
import { API_URL } from "../../../constants";
import GoogleMap from "../../GoogleMap";
import PositionSelector from "../../PositionSelector";
import Form from "../../UI/Form";
import Checkbox from "../../UI/Form/Checkbox";
import Errors from "../../UI/Form/Errors";
import Input from "../../UI/Form/Input";
import Submit from "../../UI/Form/Submit";
import useModal from "../../UI/Modal/useModal";
import {
  Cross,
  Inner,
  MapActivator,
  Step,
  Wrapper,
  MapWrapper,
} from "./styles";
import {
  useUserDispatch,
  useUserState,
} from "../../../store/context/user-context";
import withAuth from "../../../utils/withAuth";
import checkSubscription from "../../../utils/checkSubscription";
import { InvalidSubscription } from "../../../utils/globalStyles";
import { useTranslation } from 'react-i18next';

const LocationCreator = () => {
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isShowing, toggle } = useModal();
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useUserDispatch();
  const { data: userData } = useUserState();
  const { t, i18n } = useTranslation('common');

  const { isEditing, companyId, title, preset } = state || {};

  // Check if we have passed additional data to this component
  // if we have sent it, it means we are editing a location
  const [inputs, setInputs] = useState(
    preset
      ? {
          ...preset,
          radius: preset?.arriveRadius,
          location: preset?.address,
          coords: { lat: preset?.latitude, lng: preset?.longitude },
        }
      : null
  );
  const handleInputChange = (value, name) => {
    setInputs((p) => ({ ...p, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(null);
    setIsLoading(true);
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/location`,
      method: !isEditing ? "POST" : "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...inputs,
        latitude: inputs?.coords?.lat,
        longitude: inputs?.coords?.lng,
        superCompanyId: companyId,
      },
    })
      .then((res) => {
        setIsLoading(false);
        const { success, errors, companies } = res.data;
        if (!success) {
          if (errors) setErrors(errors);
          return;
        }
        dispatch({
          type: "SET_COMPANIES",
          payload: companies,
        });
        history.push("/");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  // Redirect us back to root route, when we don't have required data
  if (!companyId || !title) {
    history.push("/");
    return null;
  }
  if (
    !userData ||
    !userData?.user ||
    !userData?.companies ||
    userData?.companies?.length <= 0
  )
    return (
      <p>
        We couldn't verify your identity. <br /> <Link to="/">Go to home</Link>
      </p>
    );
  const subscription = userData.companies[0]?.subscription;
  if (!checkSubscription(subscription))
    return (
      <InvalidSubscription>
        <p>
          You don't have sufficient permissions. Make sure you have the correct
          subscription plan.
        </p>
        <Link to="/">Go to home</Link>
      </InvalidSubscription>
    );

  return (
    <>
      <Wrapper>
        <Cross onClick={() => history.push("/")}>
          <img src={close} alt="Go Back" />
        </Cross>
        <h1>{title}</h1>
        <Form onSubmit={handleSubmit}>
          <Inner>
            <Step>
              {errors?.length > 0 && (
                <Errors>
                  <h3>Form submission failed:</h3>
                  {errors.map((error, i) => (
                    <li key={i}>{error.msg}</li>
                  ))}
                </Errors>
              )}
              <h3>
                <strong>{t('location.step')} 1.</strong> {t('location.addText')}
              </h3>
              <Input
                bottomBorder
                label={t('location.radius')}
                name="radius"
                value={inputs?.radius}
                type="number"
                onChange={(val, name) =>
                  setInputs((p) => ({ ...p, [name]: +val }))
                }
              />
              <MapActivator onClick={toggle}>
              {t('location.setLocation')} <img src={arrow} alt="" />
              </MapActivator>
              <MapWrapper>
                <GoogleMap
                  noInteraction
                  height="200px"
                  center={inputs?.coords}
                  zoom={12}
                >
                  {inputs?.coords && (
                    <Marker icon={dotMarker} position={inputs?.coords} />
                  )}
                </GoogleMap>
                {inputs?.address && (
                  <span className="location">{inputs?.address}</span>
                )}
              </MapWrapper>
              <span className="presentational">
              {t('location.map')}
              </span>
            </Step>
            <Step>
              <h3>
                <strong>{t('location.step')} 2.</strong> {t('location.detailsText')}
              </h3>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    marginRight: "0.5rem",
                    color: "#b0b0b0",
                    fontFamily: "Euclid-Regular",
                  }}
                >
                  
                </span>
                <Input
                  default
                  bottomBorder
                  name="name"
                  placeholder={t('location.locationName')}
                  value={inputs?.name}
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  marginTop: "2rem",
                  marginBottom: "1rem",
                }}
              >
                <Checkbox
                  name="constructionSite"
                  label={t('location.constructionSite')}
                  isChecked={inputs?.constructionSite}
                  onChange={(_, name) =>
                    setInputs((p) => ({ ...p, [name]: !p?.[name] }))
                  }
                  disabled={subscription?.id !== "3"}
                />
                {subscription?.id !== "3" && (
                  <p
                    style={{
                      paddingTop: "0.5rem",
                      fontSize: "14px",
                      color: "rgba(0,0,0,0.4)",
                    }}
                  >
                    {t('location.permission')}
                  </p>
                )}
              </div>
              {inputs?.constructionSite && (
                <Input
                  default
                  bottomBorder
                  name="constructionSiteId"
                  placeholder={t('location.constructionSiteId')}
                  value={inputs?.constructionSiteId}
                  onChange={handleInputChange}
                />
              )}
              <div
                style={{
                  marginTop: "2rem",
                }}
              >
                <Submit
                  loading={isLoading}
                  title={`${!isEditing ? `${t('location.add')}` : `${t('location.edit')}`} ${t('location.location')}`}
                  size="medium"
                />
              </div>
            </Step>
          </Inner>
        </Form>
      </Wrapper>
      <PositionSelector
        inputs={inputs}
        setInputs={setInputs}
        isShowing={isShowing}
        toggle={toggle}
      />
    </>
  );
};

export default withAuth(LocationCreator);
