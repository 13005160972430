import React from "react";
import add from "../../../../assets/media/add.svg";

import { Wrapper } from "./styles";
const AddButton = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <img src={add} alt="Add" />
    </Wrapper>
  );
};

export default AddButton;
