import styled from "styled-components";
export const Wrapper = styled.div`
  padding: 1rem;
  max-width: 900px;
  margin: 0 auto;
  h1 {
    font-size: 40px;
    font-weight: 700;
    color: #000;
    margin: 1rem 0;
    line-height: 1.4;
  }
  @media (min-width: 992px) {
    min-height: 100vh;
    padding-top: 4rem;
  }
`;
export const Cross = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  img {
    width: 44px;
    height: 44px;
  }
  @media (min-width: 992px) {
    top: 6rem;
    right: 6rem;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 6rem;
  margin-bottom: 6rem;
  @media (min-width: 992px) {
    display: inline-flex;
    flex-direction: row;
    margin-top: 10rem;
  }
`;
export const Step = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  h3 {
    color: #48525e;
    font-size: 16px;
    margin-bottom: 2rem;
    strong {
      font-weight: 700;
    }
  }
  @media (min-width: 992px) {
    &:nth-child(1) {
      margin-right: 2rem;
    }
    &:nth-child(2) {
      margin-left: 2rem;
    }
  }
  span.presentational {
    font-size: 14px;
    display: flex;
    margin: 0.25rem 0;
    color: #48525e;
    font-family: "Euclid-Regular", sans-serif;
  }
`;
export const MapActivator = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #2774CC;
  cursor: pointer;
  margin-bottom: 1.5rem;
  &:hover {
    text-decoration: underline;
  }
  img {
    margin-left: 1rem;
  }
`;

export const MapWrapper = styled.div`
  position: relative;
  span.location {
    position: absolute;
    bottom: 1.5rem;
    right: 1rem;
    color: #575757;
    font-size: 16px;
    max-width: 150px;
    line-height: 1.3;
    font-family: "Euclid-Regular", sans-serif;
  }
`;
