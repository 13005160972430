import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 1rem;
  max-width: 1400px;
  margin: 0 auto;
`;

export const Close = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 992px) {
    top: 6rem;
    right: 10rem;
  }
`;
export const Title = styled.div`
  margin-top: 4rem;
  display: flex;
  h1 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    max-width: 550px;
    line-height: 1.4;
  }
  @media (min-width: 992px) {
    margin-top: 6rem;
  }
`;

export const Inner = styled.div`
  margin: 3rem 0;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  @media (min-width: 992px) {
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const FileInput = styled.div`
  margin-bottom: 1rem;
  position: relative;
  input {
    font-family: "Euclid-Regular", sans-serif;
    font-size: 16px;
    padding: 0.75rem;
    border: none;
    border-bottom: 1px solid #969faa;
    color: #000;
    &::placeholder {
      color: #8190a5;
      font-family: "Euclid-Regular";
    }
  }
  label {
    color: #5a6978;
    margin-right: 0.75rem;
  }
`;

export const TrashCan = styled.button`
  background: none;
  border: none;
  padding: 0;
`;
