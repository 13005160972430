import axios from "axios";
import React, { useState } from "react";
import Cookies from "universal-cookie";
import { API_URL } from "../../../constants";
import { useUserDispatch } from "../../../store/context/user-context";
import Form from "../../UI/Form";
import Errors from "../../UI/Form/Errors";
import Input from "../../UI/Form/Input";
import Submit from "../../UI/Form/Submit";
import Map from "./Map";
import { DetailsWrapper, FormWrapper, MapWrapper, Wrapper } from "./Styles";
import { useTranslation } from 'react-i18next';

const RegistrateCompany = ({ toggleModal }) => {
  const [inputs, setInputs] = useState();
  const [errors, setErrors] = useState(null);
  const [radius, setRadius] = useState(null);
  const [location, setLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation('common');

  const dispatch = useUserDispatch();

  const handleInputChange = (value, name) => {
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(null);
    setIsLoading(true);
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/superCompany`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...inputs,
        /* radius: +radius,
        latitude: location?.coords?.lat,
        longitude: location?.coords?.lng, */
        address: location?.location,
      },
    })
      .then((res) => {
        setIsLoading(false);
        const { success, errors } = res.data;
        if (!success) {
          if (errors) setErrors(errors);
          return;
        }
        const { user, companies } = res.data;
        toggleModal();
        dispatch({
          type: "SET_USER",
          payload: { user, companies },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  return (
    <Wrapper>
      <h2>{t('companyDetails.title')}</h2>
      <DetailsWrapper>
        <Form onSubmit={handleSubmit}>
          <FormWrapper>
            {errors?.length > 0 && (
              <div
                style={{
                  marginRight: "1.5rem",
                }}
              >
                <Errors>
                  <h3>Form submission failed:</h3>
                  {errors.map((error, i) => (
                    <li key={i}>{error.msg}</li>
                  ))}
                </Errors>
              </div>
            )}
            <Input
              flex
              bottomBorder
              onChange={handleInputChange}
              name="companyName"
              value={inputs?.companyName}
              label={`${t('companyDetails.name')}:`}
            />
            <Input
              flex
              bottomBorder
              onChange={handleInputChange}
              name="orgNumber"
              value={inputs?.orgNumber}
              label={`${t('companyDetails.orgNumber')}:`}
            />
            <Input
              flex
              bottomBorder
              onChange={handleInputChange}
              name="vatNumber"
              value={inputs?.vatNumber}
              label={`${t('companyDetails.vatNumber2')}:`}
            />
          </FormWrapper>
          <MapWrapper>
            <Map
              presetRadius={radius}
              presetLocation={
                location
                  ? {
                      coords: {
                        lat: location?.coords?.latitude || 0,
                        lng: location?.coords?.longitude || 0,
                      },
                      location: location?.location,
                    }
                  : null
              }
              setParentRadius={setRadius}
              setParentLocation={setLocation}
            />
          </MapWrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Submit
              loading={isLoading ? 1 : null}
              title={t('onboarding.registerCompany')}
              size="medium"
              margin="mt-5"
            />
          </div>
        </Form>
      </DetailsWrapper>
    </Wrapper>
  );
};

export default RegistrateCompany;
