import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, Inner } from "./styles";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation('common');

  return (
    <Wrapper>
      <Inner>
        <p>Copyright 2020 JobPals</p>
        <ul>
          <li>
            <Link to="/policy">{t('footer.policy')}</Link>
            <Link to="/terms">{t('footer.terms')}</Link>
            <Link to="/contact">{t('footer.contactus')}</Link>
          </li>
        </ul>
      </Inner>
    </Wrapper>
  );
};

export default Footer;
