import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import close from "../../assets/media/close.png";
import { API_URL } from "../../constants";
import withAuth from "../../utils/withAuth";
import Button from "../UI/Button";
import Errors from "../UI/Form/Errors";
import Input from "../UI/Form/Input";
import Table from "../UI/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableHeaderItem from "../UI/Table/TableHeaderItem";
import TableRow from "../UI/Table/TableRow";
import TableRowItem from "../UI/Table/TableRowItem";
import {
  Close,
  FileInput,
  Inner,
  InnerWrapper,
  Title,
  Wrapper,
  TrashCan,
} from "./styles";
import { useTranslation } from 'react-i18next';

const Documents = () => {
  const [inputs, setInputs] = useState(null);
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [errors, setErrors] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { location: companyLocation } = location?.state || {};
  const { t, i18n } = useTranslation('common');

  const { id: companyLocationId, name: locationName } = companyLocation;
  useEffect(() => {
    if (companyLocationId) {
      const cookies = new Cookies(),
        token = cookies.get("token");
      axios({
        url: `${API_URL}/document`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          companyId: companyLocationId,
        },
      })
        .then((res) => {
          const { success, documents } = res.data;
          if (!success) {
            history.replace("/");
            return;
          }
          setDocuments(documents);
        })
        .catch(() => {
          history.replace("/");
        });
    }
  }, [companyLocationId, history]);

  const handleInputChange = (val, name) => {
    setInputs((p) => ({ ...p, [name]: val }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(null);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", inputs?.name || null);
    formData.append("companyId", companyLocationId);
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/document`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        const { success, errors, documents } = res.data;
        if (!success) {
          if (errors) setErrors(errors);
          return;
        }
        setInputs(null);
        setFile(null);
        setDocuments(documents);
      })
      .catch((err) => {
        setErrors((p) => [...p, { msg: "Try again later." }]);
      });
  };

  const handleDocumentDelete = (fileName) => {
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/document`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        companyId: companyLocationId,
        fileName,
      },
    }).then((res) => {
      const { success, documents } = res.data;
      if (!success) return;
      setDocuments(documents);
    });
  };

  if (!companyLocation) {
    history.replace("/");
    return null;
  }


  return (
    <Wrapper>
      <Close onClick={() => history.push("/")}>
        <img src={close} alt="Close" />
      </Close>
      <Title>
        <h1>{t('location.documents')}</h1>
      </Title>
      <Inner>
        <InnerWrapper>
          <form
            onSubmit={handleSubmit}
            style={{
              marginBottom: "2rem",
              maxWidth: "225px",
              overflow: "hidden",
            }}
          >
            {errors?.length > 0 && (
              <Errors>
                <h3>{t('general.submissionFailed')}</h3>
                {errors.map((error, i) => (
                  <li key={i}>{error.msg}</li>
                ))}
              </Errors>
            )}
            <FileInput>
              <label htmlFor="file">{t('location.upload')}</label>
              <input
                type="file"
                name="file"
                id="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </FileInput>
            <Input
              name="name"
              label={t('location.docName')}
              bottomBorder
              default
              value={inputs?.name}
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              title={t('location.uploadDoc')}
              isDisabled={!file || (file && !inputs?.name)}
            />
          </form>
          {!documents && <p>Loading documents..</p>}
          {documents && documents?.length <= 0 && (
            <p>{t('location.noDocs')}</p>
          )}
          {documents?.length > 0 && (
            <>
              <Table title={`Documents for ${locationName}`}>
                <TableHeader>
                  <TableHeaderItem flex="0.2">{t('location.name')}</TableHeaderItem>
                  <TableHeaderItem flex="0.2" align="right">
                    {t('location.delete')}
                  </TableHeaderItem>
                </TableHeader>
                {documents?.map((document, i) => {
                  const { name, fileName, url } = document;
                  return (
                    <TableRow key={i}>
                      <TableRowItem flex="0.2">
                        <a href={url} rel="noreferrer" target="_blank">
                          {name}
                        </a>
                      </TableRowItem>
                      <TableRowItem flex="0.2" align="flex-end">
                        <TrashCan
                          onClick={() => handleDocumentDelete(fileName)}
                        >
                          {trashcan}
                        </TrashCan>
                      </TableRowItem>
                    </TableRow>
                  );
                })}
              </Table>
            </>
          )}
        </InnerWrapper>
      </Inner>
    </Wrapper>
  );
};

const trashcan = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
  </svg>
);

export default withAuth(Documents);
