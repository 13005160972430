import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import close from "../../assets/media/close.png";
import withAuth from "../../utils/withAuth";
import Employees from "./Employees";
import Plans from "./Plans";
import { Close, Inner, Title, Wrapper } from "./styles";
import Cookies from "universal-cookie";
import {
  useUserDispatch,
  useUserState,
} from "../../store/context/user-context";
import { useTranslation } from 'react-i18next';
import Footer from '../Auth/Footer'
import axios from "axios";
import { API_URL } from "../../constants";
const Cart = () => {
  const [minEmployees, setMinEmployees] = useState(1)
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);
  const history = useHistory();
  const { t, i18n } = useTranslation('common');

  const { data: userData } = useUserState();
  const { companies } = userData;
  const dispatch = useUserDispatch();
  useEffect(() => {
    const cookies = new Cookies(),
      cookieToken = cookies.get("token");
    axios({
      url: `${API_URL}/superCompanyUsers`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${cookieToken}`,
      },
      params: {
        companyId: companies[0].id
      },
    }).then((res) => {
      if (res.data.success) {
        setMinEmployees(res.data.allUsers.length)
      }
    })
  }, [])

  return (
    <>
      <Wrapper>
        <Close onClick={() => history.push("/")}>
          <img src={close} alt="Close" />
        </Close>
        <Title>
          <h1>
            {step === 1
              ? `${t('subscription.availablePlans')}`
              : step === 2
                ? `${t('subscription.noOfEmployees')}`
                : null}
          </h1>
        </Title>
        <Inner>
          {step === 1 ? (
            <Plans setData={setData} setStep={setStep} />
          ) : (
            step === 2 && <Employees data={data} setData={setData} minEmployees={minEmployees} />
          )}
        </Inner>
      </Wrapper>
      <Footer />
    </>
  );
};

export default withAuth(Cart);
