import React from "react";
import { Link } from "react-router-dom";
import pig from "../../assets/media/payment_successful_pig.svg";
import { SuccessWrapper } from "./styles";
import { useTranslation } from 'react-i18next';

const Success = () => {
  const { t, i18n } = useTranslation('common');

  return (
    <SuccessWrapper>
      <img src={pig} alt="" />
      <h2>Hoooray!</h2>
      <h4>{t('checkout.completed')}</h4>
      <Link to="/">{t('checkout.toHome')}</Link>
    </SuccessWrapper>
  );
};

export default Success;
