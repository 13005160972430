import React, { useState } from "react";
import Cookies from "universal-cookie";
import withAuth from "../../utils/withAuth";
import close from "../../assets/media/close.png";

import { Wrapper, Cross, Inner } from "./styles";
import { Link, useHistory } from "react-router-dom";
import Input from "../UI/Form/Input";
import Form from "../UI/Form";
import Submit from "../UI/Form/Submit";
import axios from "axios";
import { API_URL } from "../../constants";
import Errors from "../UI/Form/Errors";
import { useUserState } from "../../store/context/user-context";
import { InvalidSubscription } from "../../utils/globalStyles";
import checkSubscription from "../../utils/checkSubscription";
import { useTranslation } from 'react-i18next';

const Invite = () => {
  const [inputs, setInputs] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();
  const { data: user } = useUserState();
  const { t, i18n } = useTranslation('common');

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsSuccess(false);
    const cookies = new Cookies(),
      token = cookies.get("token");
    const companyId = user?.companies[0]?.id;
    axios({
      url: `${API_URL}/inviteUser`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...inputs,
        companyId,
      },
    })
      .then((res) => {
        setIsLoading(false);
        const { success, errors } = res.data;
        if (!success) {
          if (errors) setErrors(errors);
          return;
        }
        setIsSuccess(true);
        setInputs(null);
      })
      .catch(() => {
        setIsLoading(false);
        const error = { msg: "Something went wrong. Try again." };
        if (errors?.length > 0) {
          setErrors((p) => [...p, error]);
        } else {
          setErrors([error]);
        }
      });
  };

  const subscription = user.companies[0]?.subscription;
  if (!checkSubscription(subscription))
    return (
      <InvalidSubscription>
        <p>
        {t('inviteUser.noRight')}
        </p>
        <Link to="/">{t('inviteUser.goHome')}</Link>
      </InvalidSubscription>
    );

  return (
    <Wrapper>
      <Cross onClick={() => history.push("/")}>
        <img src={close} alt="Go Back" />
      </Cross>
      <h1>{t('inviteUser.title')} </h1>
      <Inner>
        <p>{t('inviteUser.content')} </p>
        {errors?.length > 0 && (
          <Errors>
            <h3>Form submission failed:</h3>
            {errors.map((error, i) => (
              <li key={i}>{error.msg}</li>
            ))}
          </Errors>
        )}
        <Form onSubmit={handleSubmit}>
          <Input
            onChange={(value, name) =>
              setInputs((p) => ({ ...p, [name]: value }))
            }
            value={inputs?.phone}
            name="phone"
            type="tel"
            auth
            placeholder={t('inviteUser.phone')} 
          />
          <Submit loading={isLoading ? 1 : null} size="medium" title={t('inviteUser.invite')}  />
          {isSuccess && (
            <span className="success">Invite successfully sent</span>
          )}
        </Form>
      </Inner>
    </Wrapper>
  );
};

export default withAuth(Invite);
