import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import dayjs from "dayjs";
import { Link, useHistory } from "react-router-dom";
import { getUser, signUpUser } from "../../../store/actions/user";
import {
  useUserDispatch,
  useUserState,
} from "../../../store/context/user-context";
import Header from "../../Main/Header";
import Form from "../../UI/Form";
import Errors from "../../UI/Form/Errors";
import { CenteredForm } from "../../UI/Form/FormStyles";
import Input from "../../UI/Form/Input";
import Submit from "../../UI/Form/Submit";
import Modal from "../../UI/Modal";
import { RegisterWrapper } from "./Styles";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import TelephoneInput from "../../UI/Form/TelephoneInput";
import { useTranslation } from 'react-i18next';

const Register = (props) => {
  const [inputs, setInputs] = useState(null);
  const [errors, setErrors] = useState(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { data: user } = useUserState();
  const dispatch = useUserDispatch();
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const { t, i18n } = useTranslation('common');

  const handleInputChange = (value, name) => {
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(null);
    setIsLoading(true);

    signUpUser(inputs)
      .then((res) => {
        setIsLoading(false);
        const { success, errors } = res.data;
        if (!success) {
          if (errors) setErrors(errors);
          return;
        }
        const { user, companies, token } = res.data;
        const cookies = new Cookies();
        cookies.set("token", token, {
          path: "/",
          expires: dayjs().add(14, "day").toDate(),
        });
        dispatch({ type: "SET_USER", payload: { user, companies } });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
    //await handleSignup();
  };

  // Redirect the user when logged in
  useEffect(() => {
    if (user) {
      history.replace("/");
    } else {
      // Check if user has token
      const cookies = new Cookies(),
        token = cookies.get("token");
      // If has token, try to get the user data
      if (token) {
        getUser()
          .then((res) => {
            const { success, errors, user, companies } = res.data;
            if (!success) {
              if (errors) setErrors(errors);
              return;
            }
            dispatch({
              type: "SET_USER",
              payload: { user, companies },
            });
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  }, [user, history, dispatch]);

  return (
    <>
      <Header />
      <Modal isShowing={true} transparent hide={() => history.goBack()}>
        <RegisterWrapper>
          <h2>{t('signUp.title')}</h2>
          {errors?.length > 0 && (
            <Errors>
              <h3>Form submission failed:</h3>
              {errors.map((error, i) => (
                <li key={i}>{error.msg}</li>
              ))}
            </Errors>
          )}
          <CenteredForm>
            <Form onSubmit={handleSubmit}>
              <Input
                bottomBorder
                name="firstName"
                placeholder={t('signUp.firstName')}
                value={inputs?.firstName || ""}
                onChange={handleInputChange}
              />
              <Input
                bottomBorder
                name="lastName"
                placeholder={t('signUp.lastName')}
                value={inputs?.lastName || ""}
                onChange={handleInputChange}
              />
              <Input
                bottomBorder
                name="number"
                placeholder={t('signUp.ssc')}
                value={inputs?.number || ""}
                onChange={handleInputChange}
              />
              <TelephoneInput
                name="phone"
                value={inputs?.phone}
                onChange={handleInputChange}
                placeholder={t('signUp.mobile')}
              />
              <Input
                bottomBorder
                name="email"
                placeholder={t('signIn.email')}
                value={inputs?.email || ""}
                onChange={handleInputChange}
              />
              <Input
                bottomBorder
                type={!isShowingPassword ? "password" : "text"}
                name="password"
                placeholder={t('signIn.password')}
                value={inputs?.password || ""}
                onChange={handleInputChange}
                showPassword
                onShowPassword={() => setIsShowingPassword((p) => !p)}
                showPasswordIcon={
                  isShowingPassword ? passwordVisible : passwordInvisible
                }
              />
              <Submit
                loading={isLoading ? 1 : null}
                size="large"
                title={t('signUp.title')}
              />
            </Form>
          </CenteredForm>
          <p>
            {t('signUp.terms')}{" "}<br />
            <Link to="/terms" target="_blank">
              {t('signUp.termsLink')}
            </Link>
          </p>
        </RegisterWrapper>
      </Modal>
    </>
  );
};

const passwordInvisible = (
  <svg
    width="22"
    height="15"
    viewBox="0 0 22 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 2C14.79 2 18.17 4.13 19.82 7.5C18.17 10.87 14.79 13 11 13C7.21 13 3.83 10.87 2.18 7.5C3.83 4.13 7.21 2 11 2ZM11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 5C12.38 5 13.5 6.12 13.5 7.5C13.5 8.88 12.38 10 11 10C9.62 10 8.5 8.88 8.5 7.5C8.5 6.12 9.62 5 11 5ZM11 3C8.52 3 6.5 5.02 6.5 7.5C6.5 9.98 8.52 12 11 12C13.48 12 15.5 9.98 15.5 7.5C15.5 5.02 13.48 3 11 3Z"
      fill="#606060"
    />
  </svg>
);

const passwordVisible = (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 3.55C14.79 3.55 18.17 5.68 19.82 9.05C19.23 10.27 18.4 11.32 17.41 12.17L18.82 13.58C20.21 12.35 21.31 10.81 22 9.05C20.27 4.66 16 1.55 11 1.55C9.73 1.55 8.51 1.75 7.36 2.12L9.01 3.77C9.66 3.64 10.32 3.55 11 3.55ZM9.93 4.69L12 6.76C12.57 7.01 13.03 7.47 13.28 8.04L15.35 10.11C15.43 9.77 15.49 9.41 15.49 9.04C15.5 6.56 13.48 4.55 11 4.55C10.63 4.55 10.28 4.6 9.93 4.69ZM1.01 1.42L3.69 4.1C2.06 5.38 0.77 7.08 0 9.05C1.73 13.44 6 16.55 11 16.55C12.52 16.55 13.98 16.26 15.32 15.73L18.74 19.15L20.15 17.74L2.42 0L1.01 1.42ZM8.51 8.92L11.12 11.53C11.08 11.54 11.04 11.55 11 11.55C9.62 11.55 8.5 10.43 8.5 9.05C8.5 9 8.51 8.97 8.51 8.92ZM5.11 5.52L6.86 7.27C6.63 7.82 6.5 8.42 6.5 9.05C6.5 11.53 8.52 13.55 11 13.55C11.63 13.55 12.23 13.42 12.77 13.19L13.75 14.17C12.87 14.41 11.95 14.55 11 14.55C7.21 14.55 3.83 12.42 2.18 9.05C2.88 7.62 3.9 6.44 5.11 5.52Z"
      fill="#606060"
    />
  </svg>
);

export default Register;
