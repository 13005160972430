import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { FormWrapper, ErrorMessage } from "./styles";
import axios from "axios";
import { API_URL } from "../../constants";
import Success from "./Success";
import {
  useUserDispatch,
  useUserState,
} from "../../store/context/user-context";
import { Link, useHistory } from "react-router-dom";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";

//import Button from "../../UI/Button";
const stripePromise = loadStripe('pk_test_51IQ5dxHbUOKJeKdhXPtNRzcxEVUalA6TpngDdIFPU9CFwq0sBEVZrbLzVBSrsiWQRRTWw81Wf9WqKGYcoSKaDiy800M8oarGIX');

const Payment = ({ data }) => {

  const [token, setToken] = useState(null);
  const [instance, setInstance] = useState(null);
  const [isFailure, setIsFailure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isCheckoutFailure, setIsCheckoutFailure] = useState(false);
  const { data: userData } = useUserState();
  const { companies } = userData;
  const dispatch = useUserDispatch();
  const [stripeCustomerId, setStripeCustomerId] = useState(null)
  const [invalidVAT, setInvalidVAT] = useState(false)

  useEffect(() => {

    if (companies[0].stripeCustomerId) {
      setStripeCustomerId(companies[0].stripeCustomerId)
    }
    else {
      const cookies = new Cookies(),
        cookieToken = cookies.get("token");
      axios({
        url: `${API_URL}/create-customer`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${cookieToken}`,
        },
        data: {
          name: companies[0].companyName,
          address: companies[0].address,
          vatNumber: companies[0].vatNumber,
          id: companies[0].id
        },
      })
        .then((res) => {

          if (!res.data.success) {
            if (res.data.code === "tax_id_invalid") {
              setInvalidVAT(true)
            }
            setIsCheckoutFailure(true);

            return;
          }
          dispatch({
            type: "SET_COMPANIES",
            payload: res.data.companies,
          });
          setStripeCustomerId(res.data.stripeCustomerId)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [])
  // Show success component when payment was successful
  if (isSuccess)
    return (
      <FormWrapper>
        <Success />
      </FormWrapper>
    );

  return (
    <FormWrapper>
      { isCheckoutFailure && (
        <Error>
          {invalidVAT ? (
            <div>
              <p> Invalid VAT number, please update your company details</p>
              <br />
              <Link to="/company-info">Update</Link>
              <br />
              <br />
            </div>
          ) : `There was a problem completing your payment. Please try again.`
          }
        </Error>
      )}
      {isFailure && (
        <Error>
          There was a problem loading the payment gateway. Try again at a later
          date.
        </Error>
      )}

      {!isFailure && stripePromise && !invalidVAT && (
        <Elements stripe={stripePromise} >
          <CheckoutForm customerId={stripeCustomerId} data={data} priceId={data.plan.subscriptionId} quantity={data.employees} setIsSuccess={setIsSuccess} />
        </Elements>
      )}
      {isWaiting && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </FormWrapper>
  );
};

const Error = ({ children }) => (
  <ErrorMessage>
    <span>Oops!</span>
    {children}
  </ErrorMessage>
);

export default Payment;
