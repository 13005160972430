import React from "react";

import { CardWrapper, Price, SelectWrapper } from "./styles";
import { useTranslation } from 'react-i18next';

const Card = ({
  title,
  description,
  price,
  onClick,
  isCurrentPlan,
  children,
}) => {
  const { t, i18n } = useTranslation('common');

  return (
    <CardWrapper>
      <h2>{title}</h2>
      <h3>{description}</h3>
      <Price>
        <h4>
          {price}
          <span>SEK</span>
        </h4>
        <p>{t('subscription.perUser')}</p>
      </Price>
      {children}
      <SelectWrapper isCurrentPlan={isCurrentPlan}>
        <button type="button" onClick={onClick} disabled={isCurrentPlan}>
          {isCurrentPlan ? "Current plan" : `${t('subscription.select')}`}
        </button>
      </SelectWrapper>
    </CardWrapper>
  );
};

export default Card;
