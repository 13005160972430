import React from "react";
import { Link } from "react-router-dom";
import Button from "../../UI/Button";
import Modal from "../../UI/Modal";
import SideModal from "../../UI/Modal/SideModal";
import useModal from "../../UI/Modal/useModal";
import useSideModal from "../../UI/Modal/useSideModal";
import CompanyDetails from "../CompanyDetails";
import TimeReport from "../TimeReport";
import { Address, Details, Wrapper } from "./Styles";
import { useTranslation } from 'react-i18next';

const CompanyCard = ({ selectedLocation }) => {
  const { id, name, orgNumber, address, adminEmail, constructionSite } = selectedLocation;
  const { isShowingSide, toggleSide } = useSideModal();
  const { t, i18n } = useTranslation('common');

  return (
    <>
      <Wrapper>
        {name && <h2>{name}</h2>}
        {orgNumber && <h3>{t('location.orgNumber')} {orgNumber}</h3>}
        <Address>
          <h3>{address}</h3>
        </Address>
        <Details>
          {adminEmail && <h3>Admin: {adminEmail}</h3>}
          <Link
            to={{
              pathname: "/location",
              state: {
                isEditing: true,
                preset: selectedLocation,
                companyId: selectedLocation?.superCompanyId,
                title: `${t('location.editLocationText')} ${selectedLocation?.name}`,
              },
            }}
          >
            {t('location.editDetails')}
          </Link>
          <Link
            to={{
              pathname: "/documents",
              state: {
                location: selectedLocation,
              },
            }}
          >
            {t('location.editDocuments')}
          </Link>
          <Link
            to={{
              pathname: "/timetables",
              state: {
                location: selectedLocation,
              },
            }}
          >
            {t('location.timetables')}
          </Link>
        </Details>
        <Button onClick={toggleSide} title={t('location.timeReport')} size="small" />
      </Wrapper>
      <SideModal isShowingSide={isShowingSide} hide={toggleSide}>
        <TimeReport id={id} constructionSite={constructionSite} />
      </SideModal>
    </>
  );
};

export default CompanyCard;
