import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import close from "../../assets/media/close.png";
import Footer from "../Auth/Footer";
import Header from "../Main/Header";
import { Wrapper, Title, Close, FormWrapper } from "./styles";
import { API_URL } from "../../constants";
import {
    useUserDispatch,
    useUserState,
} from "../../store/context/user-context";
import Form from "../UI/Form";
import Submit from "../UI/Form/Submit";
import Errors from "../UI/Form/Errors";

import Input from "../UI/Form/Input";
import Cookies from "universal-cookie";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const CompanyInfo = () => {
    const history = useHistory();
    const { data: user } = useUserState();
    const [isLoading, setIsLoading] = useState(false)
    const [inputs, setInputs] = useState(null);
    const dispatch = useUserDispatch();
    const { t, i18n } = useTranslation('common');
    const [errors, setErrors] = useState(false)

    const handleInputChange = (value, name) => {
        setInputs((p) => ({ ...p, [name]: value }));
    };

    useEffect(() => {
        const cookies = new Cookies(),
            token = cookies.get("token");

        axios({
            url: `${API_URL}/superCompany`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: "GET",

        })
            .then((res) => {
                console.log(res.data)
                setInputs(res.data.companies[0])
            })
    }, [])
    const handleSubmit = (e) => {
        setIsLoading(true)
        setErrors(false)
        e.preventDefault()
        const cookies = new Cookies(),
            token = cookies.get("token");

        axios({
            url: `${API_URL}/superCompany`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: "PUT",
            data: {
                ...inputs
            },
        })
            .then((res) => {
                setIsLoading(false)
                const { success, companies } = res.data;
                if (!success) {
                    setErrors(res.data.errors)
                }
                dispatch({
                    type: "SET_COMPANIES",
                    payload: companies,
                });
            })
            .catch((err) => {
                setIsLoading(false)

                console.log(err)
            });
    }
    return (
        <>
            <Header variant="secondary" />
            <Wrapper>
                <Close onClick={() => history.push("/")}>
                    <img src={close} alt="Close" />
                </Close>
                <Title>{t('companyDetails.title')}</Title>
                <Form onSubmit={handleSubmit}>

                    <label>
                        {t('companyDetails.name')}
                        <Input
                            onChange={(value, name) =>
                                setInputs((p) => ({ ...p, [name]: value }))
                            }
                            value={inputs?.companyName}
                            name="companyName"
                            type="text"
                            auth

                            typeSecond
                            placeholder="Company name"
                        />
                    </label>
                    <div
                        style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            width: "100%",
                        }}
                    ></div>
                    <label>
                        {t('companyDetails.address')}
                        <Input
                            onChange={(value, name) =>
                                setInputs((p) => ({ ...p, [name]: value }))
                            }
                            value={inputs?.address}
                            name="address"
                            type="text"
                            typeSecond
                            placeholder="Address"
                        />
                    </label>
                    <label>
                        {t('companyDetails.orgNumber')}

                        <Input
                            flex
                            bottomBorder
                            onChange={handleInputChange}
                            name="orgNumber"
                            value={inputs?.orgNumber}
                            typeSecond
                            placeholder="Organisation number"

                        />
                    </label>
                    <label>
                        {t('companyDetails.vatNumber')}
                        <Input
                            flex
                            bottomBorder
                            onChange={handleInputChange}
                            name="vatNumber"
                            value={inputs?.vatNumber}
                            typeSecond
                            placeholder="VAT number"
                        />
                    </label>
                    <label>
                        {t('companyDetails.adminEmail')}
                        <Input
                            flex
                            bottomBorder
                            onChange={handleInputChange}
                            name="adminEmail"
                            value={inputs?.adminEmail}
                            typeSecond
                            placeholder={t('companyDetails.adminEmail')}
                        />
                    </label>
                    <label>
                        {t('signIn.password')}
                        <Input
                            flex
                            bottomBorder
                            onChange={handleInputChange}
                            name="password"
                            value={inputs?.password}
                            typeSecond
                            type="password"
                            placeholder={t('signIn.password')}
                        />
                    </label>
                    <Submit
                        loading={isLoading ? 1 : null}
                        title={t('companyDetails.save')}
                        size="medium"
                        margin="mt-5"
                    />
                    {errors?.length > 0 && (
                        <Errors>
                            <h3>Form submission failed:</h3>
                            {errors.map((error, i) => (
                                <li key={i}>{error.msg}</li>
                            ))}
                        </Errors>
                    )}
                </Form>
            </Wrapper>
            <Footer />
        </>
    );
};

export default CompanyInfo;
