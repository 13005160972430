import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import dotMarker from "../../../assets/media/dot-marker.svg";
import { GOOGLE_MAPS_API_KEY } from "../../../constants";
import Location from "../../Location";
import Modal from "../../UI/Modal";
import mapStyles from "../Map/mapStyles";
import { CurrentLocation, LocationButton } from "./Styles";
import { useTranslation } from 'react-i18next';

Geocode.setApiKey("AIzaSyDTr6qoLLDs4Qxhwf7GfvXiwX9aM-uPAtY");

const mapContainerStyle = {
  minHeight: "200px",
  width: "100%",
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
};
const libraries = ["places"];

const Map = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [isChangeLocation, setIsChangeLocation] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(
    props.presetLocation || null
  );
  const [currentCenter, setCurrentCenter] = useState({
    lat: 55.8502712,
    lng: 12.9147853,
  });
  const [currentRadius, setCurrentRadius] = useState(
    props.presetRadius || null
  );
  const { t, i18n } = useTranslation('common');

  // Whenever there is a change in current location,
  // set the map centered to that location
  const { setParentLocation } = props || {};
  useEffect(() => {
    if (currentLocation?.coords) {
      setCurrentCenter({
        lat: currentLocation.coords.lat,
        lng: currentLocation.coords.lng,
      });
    }
    if (currentLocation && setParentLocation) {
      setParentLocation(currentLocation);
    }
  }, [currentLocation, setCurrentCenter, setParentLocation]);

  const { setParentRadius } = props || {};
  useEffect(() => {
    if (currentRadius && setParentRadius) {
      setParentRadius(currentRadius);
    }
  }, [currentRadius, setParentRadius]);

  const { setCurrentLocation: propCurrentLocation } = props || {};
  useEffect(() => {
    if (currentLocation && propCurrentLocation) {
      propCurrentLocation(currentLocation);
    }
  }, [currentLocation, propCurrentLocation]);

  return (
    <>
      <Modal isShowing={isChangeLocation} fullScreen>
        <Location
          onClose={() => setIsChangeLocation(false)}
          setCurrentLocation={setCurrentLocation}
          currentLocation={currentLocation}
          setCurrentRadius={setCurrentRadius}
          presetRadius={props.presetRadius}
        />
      </Modal>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          options={options}
          center={currentCenter}
          zoom={10}
        >
          {currentLocation?.coords && (
            <Marker icon={dotMarker} position={currentLocation.coords} />
          )}
          {currentLocation && (
            <CurrentLocation>
              <p>{currentLocation.location}</p>
            </CurrentLocation>
          )}
          <LocationButton onClick={() => setIsChangeLocation((p) => !p)}>
            {t('companyDetails.change')}
          </LocationButton>
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
