import React from "react";
import { LocationItemWrapper } from "./Styles";

const LocationItem = ({ onClick, children }) => {
  return (
    <LocationItemWrapper as="button" onClick={onClick}>
      {children}
    </LocationItemWrapper>
  );
};

export default LocationItem;
