import styled from "styled-components";

export const Wrapper = styled.footer`
  width: 100%;
  padding: 2rem 1rem;
  background: #f7f7f7;
  min-height: 90px;
  display: flex;
  align-items: center;
`;

export const Inner = styled.div`
  display: flex;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  p {
    padding: 0.5rem 1rem 0.5rem 0;
    color: #969faa;
    font-size: 14px;
    width: max-content;
  }
  ul {
    display: inline-flex;
    li {
      a {
        padding: 0.5rem 1rem;
        color: #5a6978;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media (min-width: 992px) {
    display: inline-flex;
    flex-direction: row;
  }
`;
