import React from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Wrapper } from "./styles";
import enGb from 'date-fns/locale/en-GB';
registerLocale('en-gb', enGb);

const Date = ({ date, placeholder, onChange, minDate, disabled }) => {
  return (
    <Wrapper>
      <DatePicker
        locale="en-gb"
        dateFormat="dd.MM.yyyy"
        selected={date}
        placeholderText={placeholder}
        onChange={onChange}
        minDate={minDate}
        disabled={disabled}
      />
    </Wrapper>
  );
};

export default Date;
