import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import dayjs from "dayjs";
import { DownloadReceipt, OrderHistoryWrapper } from "./styles";
import Table from "../UI/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableHeaderItem from "../UI/Table/TableHeaderItem";
import TableRow from "../UI/Table/TableRow";
import TableRowItem from "../UI/Table/TableRowItem";
import download from "../../assets/media/download.svg";
import axios from "axios";
import { API_URL } from "../../constants";
import { useUserState } from "../../store/context/user-context";
import { useTranslation } from 'react-i18next';

const OrderHistory = () => {
  const { data: user } = useUserState();
  const [invoices, setInvoices] = useState(null);
  const { companies } = user || {};
  const { t, i18n } = useTranslation('common');

  // Get the receipts
  useEffect(() => {
    if (!invoices && companies) {
      const cookies = new Cookies(),
        token = cookies.get("token");
      axios({
        url: `${API_URL}/invoices`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          companyId: companies[0]?.id,
        },
      }).then((res) => {
        const { success, invoices } = res.data;
        if (!success) return;
        setInvoices(invoices);
      });
    }
  }, [invoices, companies]);

  if (!invoices) return null;
  return (
    <OrderHistoryWrapper>
      <Table title={t('subscription.orderHistory')}>
        <TableHeader>
          <TableHeaderItem flex="0.2">{t('subscription.orderId')}</TableHeaderItem>
          <TableHeaderItem flex="0.4">{t('subscription.plan')}</TableHeaderItem>
          <TableHeaderItem flex="0.3">{t('subscription.date')}</TableHeaderItem>
          <TableHeaderItem flex="0.3">{t('subscription.total')}</TableHeaderItem>
          <TableHeaderItem flex="0.2" align="right">
            {t('subscription.reciept')}
          </TableHeaderItem>
        </TableHeader>
        {invoices?.map((invoice) => {
          const { number, amount_paid } = invoice;
          let planName;
          if (invoice?.lines?.data[0]?.description.includes('Basic')) {
            planName = "JobPals Basic"
          }
          if (invoice?.lines?.data[0]?.description.includes('Plus')) {
            planName = "JobPals Plus"
          }
          if (invoice?.lines?.data[0]?.description.includes('Construction')) {
            planName = "JobPals Construction"
          }
          return (
            <TableRow key={number}>
              <TableRowItem flex="0.2">{number}</TableRowItem>
              <TableRowItem flex="0.4">{planName}</TableRowItem>
              <TableRowItem flex="0.3">
                {dayjs.unix(invoice?.status_transitions?.paid_at).format("MM.DD.YY")}
              </TableRowItem>
              <TableRowItem flex="0.3">{(amount_paid / 100).toFixed(2)} SEK</TableRowItem>
              <TableRowItem flex="0.2" align="flex-end">
                <DownloadReceipt title="Download receipt" href={invoice?.invoice_pdf}>
                  <img src={download} alt="Receipt" />
                </DownloadReceipt>
              </TableRowItem>
            </TableRow>
          );
        })}
      </Table>
    </OrderHistoryWrapper>
  );
};

export default OrderHistory;
