import styled from "styled-components";

export const ComparisonOuter = styled.div`
  overflow: auto;
`;
export const ComparisonInner = styled.div`
  min-width: 768px;
`;

export const Wrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 30rem;
  h2 {
    font-size: 31px;
    color: #2c2c2c;
    font-weight: 700;
    margin-bottom: 2rem;
  }
`;

export const ComparisonHeader = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem 1rem;
`;

export const ComparisonHeaderItem = styled.div`
  font-size: 31px;
  color: #2c2c2c;
  font-weight: 700;
  ${({ flex }) => flex && `flex: ${flex};`}
`;

export const ComparisonBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ComparisonRow = styled(ComparisonHeader)`
  padding: 2rem 1.5rem;
  &:nth-child(odd) {
    background: #f9f9f9;
  }
`;
export const ComparisonRowItem = styled.div`
  color: #2c2c2c;
  font-size: 16px;
  ${({ flex }) => flex && `flex: ${flex};`}
`;
