import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #969faa;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    color: #5a6978;
    font-size: 24px;
    font-family: "Euclid-Semibold", sans-serif;
    font-weight: 600;
    text-align: center;
    padding: 0 15px;
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  @media only screen and (max-width: 446px) {
    button {
      width: 150px;
    }
  }
  @media only screen and (max-width: 374px) {
    button {
      width: 120px;
    }
  }
  ${(props) =>
    props.relative &&
    css`
      position: relative;
      justify-content: center;
      padding: 1rem;
      background-color: transparent;
      button {
        margin: 1rem 0.5rem;
      }
      @media only screen and (min-width: 576px) {
        button {
          margin: 1rem;
        }
      }
    `}
`;
