import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem;
  max-width: 900px;
  margin: 0 auto;
  h1 {
    font-size: 40px;
    font-weight: 700;
    color: #000;
    margin: 1rem 0;
    line-height: 1.4;
  }
  @media (min-width: 992px) {
    min-height: 100vh;
    padding-top: 4rem;
    max-width: 1200px;
  }
`;
export const Cross = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  img {
    width: 44px;
    height: 44px;
  }
  @media (min-width: 992px) {
    top: 6rem;
    right: 6rem;
  }
`;

export const Inner = styled.div`
  margin: 2rem 0;
  max-width: 600px;
  p {
    margin: 1.5rem 0;
  }
  span.success {
    display: flex;
    margin: 1.5rem 0;
    color: green;
  }
`;
