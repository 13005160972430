import axios from "axios";
import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { API_URL } from "../../constants";
import { useAppDispatch, useAppState } from "../../store/context/app-context";
import {
  useUserDispatch,
  useUserState,
} from "../../store/context/user-context";
import withAuth from "../../utils/withAuth";
import Modal from "../UI/Modal";
import Header from "./Header";
import Footer from "../Auth/Footer";

import Map from "./Map";
import SideDrawer from "./SideDrawer";
import { Wrapper } from "./Styles";
import Button from "../UI/Button";
import { useTranslation } from 'react-i18next';

//PUUDU TRANSLATION
const Main = () => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const { data: user } = useUserState();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModelOpen] = useState(false);
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false);
  const { HAS_SEEN_FIRST_LOCATION_MODAL } = useAppState();
  const dispatch = useUserDispatch();
  const appDispatch = useAppDispatch();
  const { t, i18n } = useTranslation('common');

  // Handle setting the location modal
  const amountOfLocations = user?.companies[0]?.locations?.length || 0;

  useEffect(() => {
    if (user && amountOfLocations != null) {
      if (amountOfLocations <= 0 && !HAS_SEEN_FIRST_LOCATION_MODAL)
        setIsLocationModelOpen(true);
    }
  }, [user, amountOfLocations, HAS_SEEN_FIRST_LOCATION_MODAL]);

  const handleLocationModal = (seen) => {
    appDispatch({
      type: "SET_FIRST_LOCATION_MODAL",
      payload: seen,
    });
  };

  // Handle free trial modal
  const { subscription } = user?.companies[0] || {};
  useEffect(() => {
    if (!subscription) {
      setIsTrialModalOpen(true);
    }
  }, [subscription]);
  const handleOnboardingModal = (seen) => {
    setIsTrialModalOpen(seen);
  };

  // Redirect to onboarding if the user has no companies
  if (!user || !user?.companies || user?.companies?.length <= 0) {
    history.replace("/onboarding");
    return null;
  }

  const handleFreeTrialActivation = () => {
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/startFreeTrial`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        superCompanyId: user?.companies[0]?.id,
      },
    })
      .then((res) => {
        setIsTrialModalOpen(false);
        const { success, companies } = res.data;
        if (!success) return;
        dispatch({
          type: "SET_COMPANIES",
          payload: companies,
        });
      })
     .catch(() => setIsTrialModalOpen(false));
  };

  return (
    <>
      <Header
        variant="secondary"
        applicable
        toggleMenu={() => setIsOpen((p) => !p)}
      />
      <Wrapper>
        <SideDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setCurrentLocation={setCurrentLocation}
        />
        <Map
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
        />
      </Wrapper>
      {amountOfLocations <= 0 && !HAS_SEEN_FIRST_LOCATION_MODAL && (
        <Modal
          isShowing={isLocationModalOpen}
          hide={() => {
            setIsLocationModelOpen((p) => !p);
            handleLocationModal(true);
          }}
        >
          <ModalContent>
            <h1>{t('onboarding.addFirst')}</h1>
            <h2>{t('onboarding.noLoc1')}</h2>
            <p>{t('onboarding.noLoc2')}</p>
            <Button
              title={t('onboarding.addLoc')}
              onClick={() => {
                handleLocationModal(true);
                history.push({
                  pathname: "/location",
                  state: {
                    companyId: user?.companies[0]?.id,
                    title: `Add location for ${user?.companies[0]?.companyName}`,
                  },
                });
              }}
            />
          </ModalContent>
        </Modal>
      )}
      {isTrialModalOpen && (
        <Modal
          isShowing={isTrialModalOpen}
          hide={() => {
            setIsTrialModalOpen(false);
            handleOnboardingModal(false);
          }}
        >
          <ModalContent>
            <h1>{t('onboarding.startTitle')}</h1>
            <p>{t('onboarding.startText')}
              <br></br>
              {t('onboarding.getStarted')}
              </p>
            <Button title={t('onboarding.try')} onClick={handleFreeTrialActivation} />
          </ModalContent>
        </Modal>
      )}
      <Footer />

    </>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 485px;
  h1 {
    font-size: 40px;
    font-weight: 700;
    color: #000;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 2.5rem;
  }
  p {
    line-height: 1.5;
  }
  button {
    margin-top: 1.5rem;
  }
  /*   a,
  button {
    border: none;
    display: flex;
    padding: 1rem;
    color: #fff;
    background: #2455c2;
    max-width: max-content;
    margin-top: 1.5rem;
    border-radius: 4px;
    text-decoration: none;
  } */
`;

export default withAuth(Main);
