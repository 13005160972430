import React from "react";
import { Outer, Wrapper } from "./styles";

const Checkbox = ({ name, label, isChecked, disabled, onChange }) => {
  return (
    <Outer>
      <Wrapper
        type="checkbox"
        id={name}
        defaultChecked={isChecked}
        name={name}
        disabled={disabled}
        onChange={({ target }) => onChange(target.value, name)}
      />
      <label htmlFor={name}>{label}</label>
    </Outer>
  );
};

export default Checkbox;
