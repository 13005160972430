import { Marker } from "@react-google-maps/api";
import React, { useState } from "react";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import locationIcon from "../../assets/media/locationIcon.png";
import { GEOCODE_API_KEY } from "../../constants";
import GoogleMap from "../GoogleMap";
import Button from "../UI/Button";
import Input from "../UI/Form/Input";
import Modal from "../UI/Modal";
import {
  PositionSelectorFooter,
  PositionSelectorHeader,
  Wrapper,
} from "./styles";
import { useTranslation } from 'react-i18next';

Geocode.setApiKey(GEOCODE_API_KEY);

function getCountry(addrComponents) {
  for (var i = 0; i < addrComponents.length; i++) {
    if (addrComponents[i].types[0] === "country") {
      return addrComponents[i].long_name;
    }
    if (addrComponents[i].types.length === 2) {
      if (addrComponents[i].types[0] === "political") {
        return addrComponents[i].long_name;
      }
    }
  }
  return false;
}

const PositionSelector = ({ isShowing, toggle, inputs, setInputs }) => {
  const [center, setCenter] = useState(null);
  const { t, i18n } = useTranslation('common');

  const handleChangeParentState = (obj) => {
    setInputs((p) => ({ ...p, ...obj }));
  };

  const getLocationFromCoordinates = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then((res) => {
      const result = res.results[0];
      const country = getCountry(result.address_components);
      const address = result.formatted_address;
      const { place_id } = result || {};
      handleChangeParentState({
        coords: { lat, lng },
        address,
        place_id: place_id ?? null,
        country: country ?? null,
      });
    });
  };

  const getCoordinatesFromLocation = (location) => {
    Geocode.fromAddress(location).then(
      (res) => {
        const result = res.results[0];
        const country = getCountry(result.address_components);
        const { lat, lng } = result.geometry.location;
        const { place_id } = result || {};
        handleChangeParentState({
          coords: { lat, lng },
          address: location,
          place_id: place_id ?? null,
          country: country ?? null,
        });
        setCenter({ lat, lng });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Modal isShowing={isShowing} hide={toggle} fullScreen>
      <Wrapper>
        <PositionSelectorHeader>
          {/* <Input
            bottomBorder
            label="Radius (m):"
            name="radius"
            value={inputs?.radius}
            type="number"
            onChange={(val, name) => setInputs((p) => ({ ...p, [name]: +val }))}
          /> */}
          <GooglePlacesAutocomplete
            debounce={500}
            selectProps={{
              value: {
                label: inputs?.address || "",
                value: inputs?.coords || "",
              },
              placeholder: "Location",
              styles: {
                input: (provided) => ({
                  ...provided,
                  width: "420px",
                  "@media (max-width: 500px)": {
                    width: "200px",
                  },
                }),
              },
              onChange: ({ label }) => getCoordinatesFromLocation(label),
            }}
          />
        </PositionSelectorHeader>
        <GoogleMap
          height="calc(100vh - 90px)"
          onClick={({ latLng }) =>
            getLocationFromCoordinates(latLng.lat(), latLng.lng())
          }
          center={center}
        >
          {inputs?.coords && (
            <Marker icon={locationIcon} position={inputs?.coords} />
          )}
        </GoogleMap>
        <PositionSelectorFooter>
          <Button title={t('location.save')} size="medium" onClick={toggle} />
        </PositionSelectorFooter>
      </Wrapper>
    </Modal>
  );
};

export default PositionSelector;
