import axios from "axios";
import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import close from "../../assets/media/close.png";
import { API_URL } from "../../constants";
import { useUserState } from "../../store/context/user-context";
import { Background } from "../../utils/globalStyles";
import withAuth from "../../utils/withAuth";
import {
  Cross,
  Group,
  Grouping,
  UsersHeader,
  Wrapper,
} from "../Subscription/styles";
import GroupByLocations from "./GroupByLocations";
import GroupByUsers from "./GroupByUsers";
import { Inner } from "./styles";
import { useTranslation } from 'react-i18next';
import Footer from "../Auth/Footer";



const Users = () => {
  const history = useHistory();
  const [currentGroup, setCurrentGroup] = useState(1);
  const [data, setData] = useState(null);
  const { locations, users } = data || {};
  const { data: user } = useUserState();
  const { t, i18n } = useTranslation('common');

  const handleGroupChange = (group) => {
    if (currentGroup === group) return;
    setCurrentGroup(group);
  };

  const setLocations = (data) => {
    setData((p) => ({ ...p, locations: data }));
  };

  const setUsers = (data) => {
    setData((p) => ({ ...p, users: data }));
  };

  const { companies } = user;
  useEffect(() => {
    if (!data) {
      axios({
        url: `${API_URL}/superCompanyUsers`,
        method: "GET",
        params: {
          companyId: companies[0].id,
        },
      })
        .then((res) => {
          const { success, allLocations, allUsers } = res.data;
          if (!success) return;
          setData({ locations: allLocations, users: allUsers });
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [companies, data]);

  const handleRemoveLocationFromUser = (userId, locationId) => {
    const userClone = users;
    // Remove location from the user
    const user = userClone.find((user) => user.id === userId);
    user.locations =
      user.locations.filter((location) => location.id !== locationId) || [];
    // Remove user from the location
    const locationClone = locations;
    const location = locationClone.find(
      (location) => location.id === locationId
    );
    location.employees =
      location.employees.filter((user) => user !== userId) || [];
    // Set all new users and locations
    setUsers(userClone);
    setLocations(locationClone);

    // Make the API request
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/removeUserFromLocation`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        companyId: companies[0].id,
        userId,
        locations: user.locations,
      },
    });
  };

  const handleAddUserToLocation = (userId, locationId) => {
    // Get the user
    const userClone = users;
    const user = userClone.find((user) => user.id === userId);
    // Get the location
    const locationClone = locations;
    const location = locationClone.find(
      (location) => location.id === locationId
    );
    // Add location id and role to the user
    // By default we set the role to be 'employee'
    user.locations = [...user.locations, { id: location.id, role: "employee" }];
    // Add user id to the location
    location.employees = [...location.employees, user.id];
    // Set all new users and locations
    setUsers(userClone);
    setLocations(locationClone);

    // Make the API request
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/addUserToLocation`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        companyId: companies[0].id,
        userId,
        locations: user.locations,
      },
    });
  };

  const handleRoleChange = (userId, locationId, newRole) => {
    // Find the user
    const userClone = users;
    const user = userClone.find((user) => user.id === userId);
    // Find the location in user->locations
    const location = user.locations.find(
      (location) => location.id === locationId
    );
    // Alter the location object and overwrite with the new role
    location.role = newRole;
    setUsers(userClone);

    // Make the API request
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/changeRole`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        companyId: companies[0].id,
        userId,
        locationId: location.id,
        role: newRole,
      },
    });
  };

  const handleRemoveUser = (userId) => {
    // The reason we're calling this function is to remove the user from all appearances in the data model
    // Create a clone of users
    const userClone = users;

    // Remove the user from all locations
    const locationClone = locations;
    for (const location of locationClone) {
      const { employees } = location;
      location.employees = employees.filter((employee) => employee !== userId);
    }

    // Remove the user completely
    const newUsers = userClone.filter((user) => user.id !== userId);

    // Update all locations and all users
    setLocations(locationClone);
    setUsers(newUsers);

    // Make the API request
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/removeUserFromCompany`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        companyId: companies[0].id,
        userId,
      },
    });
  };

  const subscription = companies[0]?.subscription;

  // TODO: Show a loader?
  if (!data) return null;
  return (
    <Background bg="#F9F9F9">
      <Wrapper>
        <Cross onClick={() => history.push("/")}>
          <img src={close} alt="Go Back" />
        </Cross>
        <h1>{t('users.title')}</h1>
        <UsersHeader>
          <Grouping>
            <Group
              active={currentGroup === 1}
              onClick={() => handleGroupChange(1)}
            >
              {t('users.groupByUsers')}
            </Group>
            <Group
              active={currentGroup === 2}
              onClick={() => handleGroupChange(2)}
            >
              {t('users.groupByLocations')}
            </Group>
          </Grouping>
          <Link to="/invite">{t('users.inviteUsers')}</Link>
        </UsersHeader>
        <Inner>
          {currentGroup === 1 && (
            <GroupByUsers
              locations={locations}
              users={users}
              removeUserLocation={handleRemoveLocationFromUser}
              addUserToLocation={handleAddUserToLocation}
              removeUser={handleRemoveUser}
            />
          )}
          {currentGroup === 2 && (
            <GroupByLocations
              locations={locations}
              users={users}
              removeUserLocation={handleRemoveLocationFromUser}
              addUserToLocation={handleAddUserToLocation}
              changeRole={handleRoleChange}
            />
          )}
          {users?.length >= 10 && subscription.id === "1" && (
            <p>
              {t('location.maxAmount')}
            </p>
          )}
        </Inner>
      </Wrapper>
      <Footer />
    </Background>
  );
};

export default withAuth(Users);
