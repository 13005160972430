import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem;
  max-width: 900px;
  margin: 0 auto;
  h1 {
    font-size: 40px;
    font-weight: 700;
    color: #000;
    margin: 1rem 0;
    line-height: 1.4;
  }
  @media (min-width: 992px) {
    min-height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
`;
export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;
  @media (min-width: 992px) {
    display: inline-flex;
    flex-direction: row;
  }
  h3 {
    font-size: 22px;
    color: #47525e;
    border-bottom: 1px solid #dcdcdc;
    padding: 0.25rem 1rem 0.5rem 0;
    margin-bottom: 1.5rem;
    width: max-content;
  }
`;
export const Summary = styled.div`
  margin-bottom: 2rem;
  min-width: 300px;
  margin-right: 4rem;
  @media (min-width: 992px) {
    width: calc(50% - 4rem);
  }
`;
export const Pricing = styled.div`
  max-width: 280px;
  width: 100%;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dcdcdc;
`;
export const PriceItem = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  color: #47525e;
  font-size: 16px;
  span {
    color: #000;
    font-weight: 700;
  }
`;
export const Total = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  color: #47525e;
  h2 {
    font-size: 45px;
    font-weight: 700;
    color: #000;
    line-height: 1.4;
  }
`;

export const Details = styled.div``;
export const Cross = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  img {
    width: 44px;
    height: 44px;
  }
  @media (min-width: 992px) {
    top: 6rem;
    right: 6rem;
  }
`;

export const FormWrapper = styled.div`
  margin-bottom: 6rem;
  max-width: 475px;
  @media (min-width: 992px) {
    margin-bottom: 0;
    max-width: 400px;
    min-width: 320px;
  }
  .lds-ring {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 2rem auto;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #2774CC;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2774CC transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    margin-bottom: 2.5rem;
  }
  h2 {
    font-size: 20px;
    color: #24c23e;
    font-weight: 700;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 12px;
  }
  h4 {
    font-size: 16px;
    color: #000;
    text-align: center;
    line-height: 1.5;
  }
  a {
    color: #2455c2;
    font-size: 16px;
    display: flex;
    padding: 1rem;
    margin: 1rem auto 0 auto;
    width: max-content;
    font-weight: 700;
  }
`;

export const ErrorMessage = styled.div`
  line-height: 1.4;
  margin: 0.5rem 0 1rem 0;
  font-family: "Euclid-Regular";
  span {
    color: red;
    width: 100%;
    display: flex;
  }
`;


export const Label = styled.label`
  margin: 10px 0;
`;

export const CreditCard = styled.div`
  margin: 20px 0;
  background: #f0f0f0;
  padding: 15px 20px;
  border-radius: 5px;
  border: ${props => props.selected ? 'solid 1px #04c72e' : 'solid 1px transparent'};
  transition: all 0.1s;
  cursor: pointer;
  p {
    text-align: right;
    strong {
      font-family: "Euclid-Bold";
      margin-bottom: 10px;
      display: block;
    }
  }
`;