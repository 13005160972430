import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import arrow from "../../../assets/media/arrow_right.svg";
import useOnClickOutside from "../../../utils/useOnClickOutside";
import AddButton from "../../UI/Button/AddButton";
import Dropdown from "./Dropdown";
import EmptyDropdown from "./Dropdown/EmptyDropdown";
import LocationItem from "./LocationItem";
import { DrawerItemWrapper, SideDrawerHeader } from "./Styles";
import { useTranslation } from 'react-i18next';

const DrawerItem = ({
  id,
  title,
  locations,
  setCurrentLocation,
  setIsNavOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));
  const { t, i18n } = useTranslation('common');

  return (
    <DrawerItemWrapper ref={ref}>
      {title}
      {locations && (
        <>
          <span
            style={isOpen ? { transform: "rotate(180deg)" } : null}
            onClick={() => setIsOpen((p) => !p)}
          >
            <img src={arrow} alt="View" />
          </span>
          {isOpen && (
            <Dropdown onClose={() => setIsOpen(false)}>
              <SideDrawerHeader>
                <h3>{t('sideDrawer.locations')}</h3>
                <AddButton
                  onClick={() =>
                    history.push({
                      pathname: "/location",
                      state: {
                        companyId: id,
                        title: `${t('location.addText')} ${title}`,
                      },
                    })
                  }
                />
              </SideDrawerHeader>
              <div style={{ marginBottom: "2rem" }}>
                {locations?.length <= 0 && (
                  <EmptyDropdown companyName={title} />
                )}
                {locations?.length > 0 &&
                  locations.map((location) => {
                    const { id, name } = location;
                    return (
                      <LocationItem
                        key={id}
                        onClick={() => {
                          setIsOpen(false);
                          setIsNavOpen(false);
                          // Send coordinates to the map
                          setCurrentLocation(location);
                        }}
                      >
                        {name}
                      </LocationItem>
                    );
                  })}
              </div>
            </Dropdown>
          )}
        </>
      )}
    </DrawerItemWrapper>
  );
};

export default DrawerItem;
