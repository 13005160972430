import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem;
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  box-shadow: none;
  h2 {
    color: #343f4b;
    font-size: 32px;
    line-height: 51px;
    margin-bottom: 0.75rem;
    font-weight: 600;
    font-family: "Euclid-Semibold", sans-serif;
  }
  h3 {
    color: #5a6978;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    font-family: "Euclid-Regular", sans-serif;
  }
  @media only screen and (min-width: 560px) {
    padding: 3rem;
    h3 {
      font-size: 18px;
    }
    h2 {
      font-size: 40px;
    }
  }
`;

export const Address = styled.div`
  margin: 1.5rem 0;
  h3 {
    max-width: 160px;
    font-family: "Euclid-Regular", sans-serif;
  }
`;

export const Details = styled.div`
  button {
    color: #2774CC;
    text-decoration: underline;
    font-weight: 500;
    margin: 1rem 0;
    font-family: "Euclid-Medium";
  }
  a {
    display: flex;
    margin: 1rem 0;
    color: #2774CC;
    font-weight: 700;
    font-family: "Euclid-Bold", sans-serif;
  }
  @media only screen and (min-width: 560px) {
    a {
      font-size: 18px;
    }
  }
`;
