import React from "react";
import { UserWrapper } from "./styles";
import placeholderUserProfile from "../../assets/media/user.svg";

const User = ({ img, name }) => {
  return (
    <UserWrapper>
      <img src={img ?? placeholderUserProfile} alt={name || ""} />
      <span>{name}</span>
    </UserWrapper>
  );
};

export default User;
