import React from "react";
import ReactDOM from "react-dom";
import { ModalWrapper, Close } from "./Styles";
import close from "../../../assets/media/close.png";

const Modal = (props) =>
  props.isShowing
    ? ReactDOM.createPortal(
        <ModalWrapper
          fullScreen={props.fullScreen}
          transparent={props.transparent}
        >
          {props.hide && (
            <Close onClick={props.hide}>
              <img src={close} alt="Close" />
            </Close>
          )}
          {props.children}
        </ModalWrapper>,
        document.body
      )
    : null;

export default Modal;
