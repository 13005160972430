import { useState } from "react";

const useSideModal = () => {
  const [isShowingSide, setIsShowingSide] = useState(false);
  const reload = () => window.location.reload();

  function toggleSide() {
    setIsShowingSide(!isShowingSide);
  }

  return { isShowingSide, toggleSide, reload };
};

export default useSideModal;
