import React from "react";
import { SelectWrapper } from "./Styles";
import Select from "react-select";

const ReactSelect = (props) => {
  const {
    placeholder,
    name,
    value,
    options,
    disabled,
    onChange,
    isMulti,
    noMargin,
    autoMargin,
  } = props || {};

  const customStyles = {
    control: (styles) => ({
      ...styles,
      padding: 3,
      borderColor: "#8492A6",
    }),
    placeholder: (styles) => ({ ...styles, color: "#47525E" }),
  };

  return (
    <SelectWrapper noMargin={noMargin ? 1 : null} autoMargin={autoMargin ? 1 : null}>
      <Select
        styles={customStyles}
        placeholder={placeholder}
        name={name}
        value={value || null}
        options={options}
        isDisabled={disabled}
        isMulti={isMulti}
        onChange={onChange}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </SelectWrapper>
  );
};

export default ReactSelect;
