import React, { useEffect } from "react";
import {
  Wrapper,
  Inner,
  Summary,
  Pricing,
  PriceItem,
  Total,
  Details,
  Cross,
} from "./styles";
import close from "../../assets/media/close.png";
import { useHistory, useLocation } from "react-router-dom";
import Payment from "./Payment";
import withAuth from "../../utils/withAuth";
import { API_URL } from "../../constants";
import {
  useUserDispatch,
  useUserState,
} from "../../store/context/user-context";
import { useTranslation } from 'react-i18next';
import Footer from "../Auth/Footer"

const Checkout = () => {
  const history = useHistory();
  const location = useLocation();
  const { data: user } = useUserState();
  const company = user?.companies[0]
  // Get data from params
  const { state } = location || {};
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    // Redirect the user when state is not set
    if (!state) {
      history.replace("/");
      return;
    }
  }, [state, history]);

  if (!state) return null;
  const vatCountry = company?.vatNumber.substring(0, 2)
  const vatMultiplier = vatCountry === "SE" ? 0.25 : 0
  const totalVAT = +(state.total * vatMultiplier + state.total).toFixed(2);

  return (
    <>
      <Wrapper>
        <Cross onClick={() => history.push("/")}>
          <img src={close} alt="Go Back" />
        </Cross>
        <h1>
          {t('checkout.title')}<br /> JobPals
      </h1>
        <Inner>
          <Summary>
            <h3>{t('checkout.orderSummary')}</h3>
            <Pricing>
              <PriceItem>
                <h4>{t('checkout.plan')}:</h4>
                <span>{state.plan.name === "JobPals Construction" ? `${t('subscription.constTitle')}` : state.plan.name}</span>
              </PriceItem>
              <PriceItem>
                <h4>{t('checkout.employees')}:</h4>
                <span>{state.employees}</span>
              </PriceItem>
              <PriceItem>
                <h4>{t('checkout.vat')}:</h4>
                <span>{vatCountry === "SE" ? "25%" : "0%"}</span>
              </PriceItem>
              <PriceItem>
                <h4>{t('checkout.subtotal')}:</h4>
                <span>{state?.total?.toFixed(2)} SEK</span>
              </PriceItem>
            </Pricing>
            <Total>
              <span>{t('checkout.total')}</span>
              <h2>{`${totalVAT}`} SEK</h2>
            </Total>
          </Summary>
          <Details>
            <Payment data={{ ...state, total: totalVAT, subTotal: state.total }} />
          </Details>
        </Inner>
      </Wrapper>
      <Footer />
    </>
  );
};

export default withAuth(Checkout);
