import styled, { css } from "styled-components";

export const HeaderWrapper = styled.div`
  padding: 1rem;
  height: 83px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: #343f4b;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
  }
  img {
    max-width: 50px;
  }
  span {
    font-size: 20px;
    font-family: "Euclid-Bold";
    display: flex;
    margin-left: 15px;
    letter-spacing: 2px;
    color: #343f4b;
    font-weight: 700;
  }
  h2 {
    margin: auto;
    font-size: 20px;
    font-family: "Euclid-Bold", sans-serif;
    font-weight: 700;
    color: #343f4b;
  }
  @media only screen and (min-width: 1200px) {
    padding: 1.5rem 3rem;
  }
  ${({ variant }) => {
    if (variant === "secondary") {
      return css`
        background: #f9fafc;
        border-bottom: 1px solid #d3dce6;
      `;
    }
  }}
`;

export const Burger = styled.div`
  span {
    display: flex;
    padding: 0.5rem 0.75rem;
    background: #eaeaea;
    letter-spacing: 1px;
    font-size: 15px;
    text-transform: uppercase;
  }
`;

export const User = styled.div`
  position: relative;
  z-index: 1;
  button {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Euclid-Regular";
  }
  @media (max-width: 992px) {
    display: none;
  }
`;
export const UserDropdown = styled.ol`
  position: absolute;
  top: 2rem;
  left: -2rem;
  background: #fff;
  width: calc(100% + 4rem);
  border: 1px solid #d3dce6;
  button {
    width: 100%;
    padding: 1rem;
    border: none;
    transition: 0.25s all ease;
    font-family: "Euclid-Regular";
    display: flex;
    justify-content: flex-start;
    &:hover {
      background: #eceff5;
    }
  }
  display: none;
  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `}
`;


export const LanguageSelect = styled.div`
  margin-left: auto;
  margin-right: 40px;
  display: flex;
  align-items: center;
  button {
    background: none;
    border: none;
    img {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 568px) {
    margin-right: 10px;
    p {
      display: none;
    }
  }
`;