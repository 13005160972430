import styled from "styled-components";

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f9f9f9;
  padding: 1rem;
  margin: 1rem 0;
  li {
    padding: 0.35rem;
    margin-left: 1rem;
    color: red;
    list-style-type: disc;
    line-height: 1.3;
  }
  h3 {
    margin-bottom: 1rem;
    color: #5a6978;
  }
`;
