import React from "react";
import { Wrapper, DropdownNavigator } from "./styles";
import { OnlyMobile } from "../../../../utils/globalStyles";
import arrow from "../../../../assets/media/arrow_down.svg";

const Dropdown = ({ children, onClose }) => {
  return (
    <Wrapper>
      <OnlyMobile>
        <DropdownNavigator onClick={onClose}>
          <img src={arrow} alt="Go back" />
          <span>Go back</span>
        </DropdownNavigator>
      </OnlyMobile>
      {children}
    </Wrapper>
  );
};

export default Dropdown;
