import styled, { css } from "styled-components";

export const SmallFormWrapper = styled.div`
  padding: 2rem;
  max-width: 400px;
  width: 100%;
  margin: auto;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const SmallFormInner = styled.div`
  h2 {
    ${(props) =>
      props.title &&
      css`
        text-align: center;
        color: #343f4b;
        margin-bottom: 3rem;
      `}
  }

  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 18px;
    }
  }
`;

export const CenteredForm = styled.div`
  position: relative;
  width: 287px;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    div {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
  ${(props) =>
    props.smaller &&
    css`
      @media only screen and (max-width: 575px) {
        width: 220px;
      }
    `}
`;
