import React, { useCallback, useEffect, useRef, useState } from "react";
import arrow from "../../../assets/media/arrow_down.svg";
import useOnClickOutside from "../../../utils/useOnClickOutside";
import Delete from "../../UI/Delete";
import ReactSelect from "../../UI/Form/Select";
import {
  AddLocationButton,
  AddToLocation,
  AddToLocationSelectWrapper,
  LocationSelectorDropdown,
  LocationSelectorDropdownItem,
  LocationSelectorWrapper,
} from "./styles";
import { useTranslation } from 'react-i18next';

const LocationSelector = ({
  locations,
  userId,
  allLocations,
  removeUserLocation,
  addUserToLocation,
}) => {
  const [options, setOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const { t, i18n } = useTranslation('common');

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  // Handle deleting a location from the list
  const handleLocationDelete = (id) => {
    // Call the parent [removeUserLocation] function to remove a user from a location
    removeUserLocation(userId, id);

    // Generate new options
    generateOptions();

    // TODO: Make the API request
  };

  // Handle adding locations to the list
  const handleAddLocations = () => {
    // Locations to be added are in [selectedOptions]
    if (!selectedOptions || selectedOptions?.length <= 0) return;
    // We add locations to the list immediately and then call the API request
    const newOptions = locations || [];

    for (const option of selectedOptions) {
      const { label, value, users } = option;
      newOptions.push({
        id: value,
        name: label,
        users,
      });
      // Call the parent [addUserToLocation] function to add a new user to a location
      addUserToLocation(userId, value);
    }

    // TODO: Make the API request

    // Generate new options
    generateOptions();
    // Clear selected options
    setSelectedOptions(null);
  };

  const generateOptions = useCallback(() => {
    // If the user doesn't have any current locations, set all locations to options
    if (!locations || locations?.length <= 0) {
      const options = allLocations?.map((location) => {
        const { name, id, users } = location;
        return {
          label: name,
          value: id,
          users,
        };
      });
      setOptions(options);
      return;
    }

    // We want this to run whenever [locations] changes to generate new options
    // We want to filter out all the [locations] that are already present
    const filtered = allLocations.filter((location) =>
      locations.some((e) => e.id !== location.id)
    );
    if (!filtered || filtered?.length <= 0) return;
    setOptions(
      filtered.map((option) => {
        const { name, id, users } = option;
        return { label: name, value: id, users };
      })
    );
  }, [allLocations, locations]);

  // Generate valid locations for select options
  useEffect(() => {
    generateOptions();
  }, [generateOptions]);

  return (
    <LocationSelectorWrapper ref={ref} onClick={() => setOpen((p) => !p)}>
      {`${locations?.length} ${
        locations?.length === 1 ? `${t('users.locationSmall')}`: `${t('users.locationsSmall')}`
      }`}
      <span>
        <img src={arrow} alt="Change" />
      </span>
      {open && (
        <LocationSelectorDropdown onClick={(e) => e.stopPropagation()}>
          <h3>{t('users.locations')}</h3>
          {locations?.length <= 0 && <p>{t('users.notSet')}</p>}
          {locations?.length > 0 &&
            locations.map((location) => {
              const { id, name } = location;
              return (
                <LocationSelectorDropdownItem key={id}>
                  <h4>{name}</h4>
                  <Delete onClick={() => handleLocationDelete(id)} />
                </LocationSelectorDropdownItem>
              );
            })}
          <AddToLocation>
            <h3>{t('users.addUserToLocation')}</h3>
            <AddToLocationSelectWrapper>
              <ReactSelect
                placeholder={
                  !options || locations?.length === allLocations?.length
                    ? `${t('users.noToadd')}`
                    : `${t('users.select')}`
                }
                disabled={
                  !options || locations?.length === allLocations?.length
                }
                options={options}
                value={selectedOptions}
                isMulti
                noMargin
                onChange={(val) => setSelectedOptions(val)}
              />
              <AddLocationButton
                isDisabled={
                  !options || !selectedOptions || selectedOptions?.length <= 0
                }
                onClick={handleAddLocations}
              >
                {t('users.add')}
              </AddLocationButton>
            </AddToLocationSelectWrapper>
          </AddToLocation>
        </LocationSelectorDropdown>
      )}
    </LocationSelectorWrapper>
  );
};

export default LocationSelector;
