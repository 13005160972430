import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: calc(100vh - 195px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    color: #343f4b;
    font-size: 32px;
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: 700;
  }
  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 40px;
    }
  }
`;

export const InnerWrapper = styled.div`
  p {
    color: #5a6978;
    text-align: center;
    line-height: 28px;
  }
  @media only screen and (min-width: 768px) {
    p {
      font-size: 18px;
    }
  }
`;
