import styled from "styled-components";

export const RegisterWrapper = styled.div`
  padding: 4rem 2rem 2rem 2rem;
  width: 600px;
  min-height: 682px;
  max-height: 90vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  h2 {
    font-size: 40px;
    color: #343f4b;
    font-family: "Euclid-Bold", sans-serif;
    margin-bottom: 3rem;
    font-weight: 700;
  }
  input {
    margin: 0.25rem 0;
    padding-left: 0.75rem;
    padding-bottom: 0.75rem;
  }
  p {
    max-width: 250px;
    text-align: center;
    line-height: 22px;
    font-family: "Euclid-Regular", sans-serif;
    color: #969faa;
    margin-top: 1rem;
    a {
      color: #969faa;
      text-decoration: none;
    }
  }
`;
