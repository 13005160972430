import React, { useRef } from "react";
import ReactDOM from "react-dom";
import close from "../../../assets/media/close.png";
import useOnClickOutside from "../../../utils/useOnClickOutside";
import { Close, SideModalInnerWrapper, SideModalWrapper } from "./Styles";

const SideModal = (props) => {
  const ref = useRef();
  useOnClickOutside(ref, () => props.hide());
  if (!props.isShowingSide) return null;
  return ReactDOM.createPortal(
    <SideModalWrapper>
      <SideModalInnerWrapper ref={ref}>
        <Close onClick={props.hide}>
          <img src={close} alt="Close" />
        </Close>
        {props.children}
      </SideModalInnerWrapper>
    </SideModalWrapper>,
    document.body
  );
};

export default SideModal;
