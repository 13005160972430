import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../../constants";
import { useUserState } from "../../../store/context/user-context";
import Card from "./Card";
import Comparison from "./Comparison";
import { Wrapper, PlanOuter, PlanWrapper } from "./styles";
import { useTranslation } from 'react-i18next';

const Plans = ({ setData, setStep }) => {
  const [plans, setPlans] = useState(null);
  const [isFetched, setIsFetched] = useState(null);
  const history = useHistory();
  const { data: user } = useUserState();
  const { t, i18n } = useTranslation('common');

  const userSubscription = user?.companies[0]?.subscription;

  // Get the plans

  useEffect(() => {
    axios({
      url: `${API_URL}/plans`,
      method: "GET",
    })
      .then((res) => {
        const plans = res.data;
        if (!plans) return;
        // Order the plans in the correct order
        const orderedPlans = plans.sort(
          (a, b) => a.subscriptionId - b.subscriptionId
        );
        setPlans(orderedPlans);
      })
      .catch(() => setIsFetched(true));
  }, []);

  const setSelection = (selection) => {
    setData((p) => ({ ...p, plan: selection }));
    setStep(2);
  };

  // Return nothing while request is not complete
  if (!plans && !isFetched) return null;

  // When request is completed, but there isn't any results
  if (!plans && isFetched) {
    history.push("/");
    return null;
  }
  return (
    <PlanOuter>
      <Wrapper>
        <PlanWrapper>
          {plans.map((plan) => {
            const { features, name, pricePerUser, subscriptionId } = plan;
            const sortedFeatures = features.sort((a, b) => a.order - b.order);
            let description = ""
            let title = name;
            if (subscriptionId === "price_1IQ78JHbUOKJeKdhW56dvw1Z") {
              description = t('subscription.constDesc')
              title = t('subscription.constTitle')
            }
            else if (subscriptionId === "price_1IQ8f6HbUOKJeKdhmQCHIb30") {
              description = t('subscription.plusDesc')
            }
            else {
              description = t('subscription.basicDesc')
            }
            return (
              <Card
                key={subscriptionId}
                title={title}
                description={description}
                price={pricePerUser.toFixed(2)}
                isCurrentPlan={
                  subscriptionId === +userSubscription?.id &&
                    userSubscription?.status !== "Cancelled" &&
                    userSubscription?.status !== "Expired" &&
                    userSubscription?.status !== "Free trial"
                    ? 1
                    : null
                }
                onClick={() => setSelection(plan)}
              >
                {subscriptionId === "price_1IQ78JHbUOKJeKdhW56dvw1Z" && (
                  <p>
                    * {t('subscription.constAddition')}
                  </p>
                )}
              </Card>
            );
          })}
        </PlanWrapper>
      </Wrapper>
      <Comparison plans={plans} />
    </PlanOuter>
  );
};

export default Plans;
