import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Submit from "../UI/Form/Submit";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../constants";
import { Label, ErrorMessage, CreditCard } from "./styles";
import Cookies from "universal-cookie";

import {
    useUserDispatch,
    useUserState,
} from "../../store/context/user-context";
import { useTranslation } from 'react-i18next';


const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null)
    const [isWaiting, setIsWaiting] = useState(false);
    const [isCheckoutFailure, setIsCheckoutFailure] = useState(false);
    const [isRetry, setIsRetry] = useState(false)
    const { data: userData } = useUserState();
    const dispatch = useUserDispatch();

    const { companies } = userData;
    const [isExisting, setIsExisting] = useState(false)
    //CARD STUFF
    const [latestInvoiceId, setLatestInvoiceId] = useState(null)
    const [creditCards, setCreditCards] = useState(null)
    const [selectedCard, setSelectedCard] = useState(null)
    const { t, i18n } = useTranslation('common');

    const options = {
        style: {
            base: {
                iconColor: '#c4f0ff',
                fontSize: '16px',
                lineHeight: '60px',
                color: '#424770',
                '::placeholder': {
                    color: '#dcdcdc',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    }
    useEffect(() => {
        if (companies[0]?.stripeCustomerId && companies[0]?.subscription.status != "Cancelled" && companies[0].subscription.stripeId) {
            setIsExisting(true)
            const cookies = new Cookies(),
                cookieToken = cookies.get("token");
            axios({
                url: `${API_URL}/customer-credit-card`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${cookieToken}`,
                },
                data: {
                    customerId: companies[0]?.stripeCustomerId,
                }
            })
                .then((result) => {
                    setCreditCards(result.data.data)
                })
                .catch((err) => {
                    setIsExisting(false);
                })
        }
    }, [])

    function addNewCard(e) {
        e.preventDefault();
        setCreditCards(false)
        setIsExisting(false)
    }
    const selectCard = (creditCard) => {
        setSelectedCard(creditCard)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setIsCheckoutFailure(false);
        if (!stripe || !elements) {
            setIsLoading(false);

            return;
        }

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
        })
        if (error) {
            setIsCheckoutFailure(true);
            setErrorMsg('There was a problem completing your payment. Please try again.')
            setIsLoading(false);

        } else {
            // IS RETRY UPDATE PAYMENT METHOD FOR CUSTOMER
            const cookies = new Cookies(),
                cookieToken = cookies.get("token");
            if (!isRetry) {
                axios({
                    url: `${API_URL}/create-subscription`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${cookieToken}`,
                    },
                    data: {
                        customerId: props.customerId,
                        paymentMethodId: paymentMethod.id,
                        priceId: props.priceId,
                        quantity: props.quantity,
                        planData: props.data,
                        companyId: companies[0].id,
                        vatNumber: companies[0].vatNumber
                    }
                })
                    .then((result) => {
                        const { companies } = result.data;

                        dispatch({
                            type: "SET_COMPANIES",
                            payload: companies,
                        });
                        const subscription = result.data.subscription;
                        if (subscription.status === 'active') {
                            setIsLoading(false);
                            props.setIsSuccess(true);
                            //HANDLE UPGRADE
                        }
                        //3DS
                        if (subscription.latest_invoice.payment_intent.status === "requires_action") {
                            stripe
                                .confirmCardPayment(subscription.latest_invoice.payment_intent.client_secret, {
                                    payment_method: paymentMethod.id,
                                }).then((result) => {
                                    if (result.error) {
                                        console.log(result.error)
                                        setIsCheckoutFailure(true);
                                        setErrorMsg(result.error.message)
                                        setIsRetry(true);
                                        setLatestInvoiceId(subscription.latest_invoice.id)
                                        setIsLoading(false);

                                    }
                                    else {
                                        if (result.paymentIntent.status === 'succeeded') {
                                            setIsLoading(false);
                                            props.setIsSuccess(true);

                                        }
                                    }
                                })
                        }
                        if (
                            subscription.latest_invoice.payment_intent.status ===
                            'requires_payment_method'
                        ) {
                            setIsCheckoutFailure(true);
                            setIsRetry(true);
                            setLatestInvoiceId(subscription.latest_invoice.id)
                            setErrorMsg('Card declined. Please try again with another card')
                            setIsLoading(false);

                        }

                    })
                    .catch((err) => {
                        setIsLoading(false);
                        setErrorMsg(err.response.data.error.message)
                        setIsCheckoutFailure(true);
                    })
            }
            // IS RETRY UPDATE PAYMENT METHOD FOR CUSTOMER
            else {
                axios({
                    url: `${API_URL}/retry-invoice`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${cookieToken}`,
                    },
                    data: {
                        customerId: props.customerId,
                        paymentMethodId: paymentMethod.id,
                        priceId: props.priceId,
                        invoiceId: latestInvoiceId,
                    }
                })
                    .then((response) => {
                        if (response.data.payment_intent.status === 'requires_payment_method') {
                            stripe
                                .confirmCardPayment(response.data.payment_intent.client_secret, {
                                    payment_method: paymentMethod.id,
                                }).then((result) => {
                                    if (result.error) {
                                        setIsCheckoutFailure(true);
                                        setErrorMsg(result.error.message)
                                        setIsLoading(false);
                                    }
                                    else {
                                        if (result.paymentIntent.status === 'succeeded') {
                                            setIsLoading(false);
                                            props.setIsSuccess(true);
                                        }
                                    }
                                })
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        setErrorMsg(err)
                        setIsCheckoutFailure(true);
                    })
            }
        };

    };

    const upgradeSubscription = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const cookies = new Cookies(),
            cookieToken = cookies.get("token");
        axios({
            url: `${API_URL}/upgrade-plan`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${cookieToken}`,
            },
            data: {
                customerId: props.customerId,
                priceId: props.priceId,
                quantity: props.quantity,
                planData: props.data,
                companyId: companies[0].id,
                vatNumber: companies[0].vatNumber,
                default_payment_method: selectedCard ? selectedCard.id : null,
                subscriptionId: companies[0].subscription.stripeId
            }
        })
            .then((response) => {
                setIsLoading(false);
                props.setIsSuccess(true);
                const { companies } = response.data;

                dispatch({
                    type: "SET_COMPANIES",
                    payload: companies,
                });
                //HANDLE UPGRADE
            })
            .catch((err) => {
                setIsLoading(false)

            })
    }
    //TODO REQUEST TO BACKEND TO CREATE SUBSCRIPTION FOR THE USER
    return (
        <div>
            {!isExisting && !creditCards ? (
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: isWaiting ? "none" : "block",
                    }}
                >
                    <Label>
                        {t('checkout.cardNumber')}
                        <CardNumberElement
                            options={options}
                        />
                    </Label>
                    <Label>
                        {t('checkout.plan')}
                        <CardExpiryElement
                            options={options}
                        />
                    </Label>
                    <Label>
                        CVC
    <CardCvcElement
                            options={options}
                        />
                    </Label>

                    <Submit
                        id="submit-button"
                        loading={isLoading || !stripe}
                        type="submit"
                        size="medium"
                        title={t('checkout.pay')}
                        margin="mt-3"
                    />
                </form>
            ) :
                <form onSubmit={upgradeSubscription}>
                    <h4>{t('checkout.availableCards')}</h4>
                    {creditCards?.map((creditCard, index) => {
                        return (
                            <CreditCard onClick={() => selectCard(creditCard)} selected={selectedCard?.id === creditCard.id ? true : !selectedCard && index === 0 ? true : false}>
                                <div>
                                    <p><strong>{creditCard?.card?.brand?.toUpperCase()}</strong></p>
                                    <p> **** **** ****{creditCard?.card?.last4}</p>
                                </div>
                            </CreditCard>
                        )
                    })}
                    <a href="#" onClick={addNewCard}>
                        {t('checkout.addNewCard')}
                    </a>
                    <Submit
                        id="submit-button"
                        loading={isLoading || !stripe}
                        type="submit"
                        size="medium"
                        title={t('checkout.upgrade')}
                        margin="mt-3"
                    />
                </form >
            }
            {
                isCheckoutFailure ? (
                    <Error>
                        {errorMsg}
                    </Error>
                ) : null
            }

        </div >

    )
}
const Error = ({ children }) => (
    <ErrorMessage>
        <span>Oops!</span>
        {children}
    </ErrorMessage>
);
export default CheckoutForm;
