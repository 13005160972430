import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";

const TelephoneInput = ({ value, name, placeholder, onChange }) => {
  return (
    <PhoneInput
      country={"se"}
      value={value || null}
      onChange={(phone) => onChange(phone, name)}
      inputProps={{
        placeholder: placeholder,
        name: name,
      }}
      containerStyle={{
        height: "40px",
        marginBottom: "1rem",
        border: "none",
        fontFamily: "Euclid-Regular",
        fontSize: "16px",
        color: "#8190a5",
      }}
      buttonStyle={{
        width: "40px",
        height: "40px",
        border: "none",
        paddingBottom: "1px",
      }}
      inputStyle={{
        width: "100%",
        padding: "6px 4px 6px 48px",
        height: "40px",
        border: "none",
        borderBottom: "1px solid #969faa",
        fontFamily: "Euclid-Regular",
        fontSize: "16px",
      }}
      inputmode="numeric"
      specialLabel={placeholder}
    />
  );
};

export default TelephoneInput;
