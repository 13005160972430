import React from "react";

import { DeleteWrapper } from "./styles";
import deleteIcon from "../../../assets/media/trashcan.svg";

const Delete = ({ onClick }) => {
  return (
    <DeleteWrapper type="button" onClick={onClick}>
      <img src={deleteIcon} alt="Delete" />
    </DeleteWrapper>
  );
};

export default Delete;
