import styled, { css } from "styled-components";

export const Outer = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  label {
    padding-left: 10px;
    cursor: pointer;
    user-select: none;
    color: #5e636c;
  }
`;

export const Wrapper = styled.input`
  padding: 1rem;
  width: 20px;
  height: 20px;
  border-radius: 999px;
  border: 1px solid #8492a6;
  cursor: pointer;
  ${({ isChecked }) => isChecked && css``}
`;
