import React from "react";
import { TableWrapper, Wrapper, TableTitle } from "./styles";

const Table = ({ children, title }) => {
  return (
    <TableWrapper>
      <Wrapper>
        {title && <TableTitle>{title}</TableTitle>}
        {children}
      </Wrapper>
    </TableWrapper>
  );
};

export default Table;
