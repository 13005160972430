import React, { useState } from "react";
import { Link } from "react-router-dom";
import { resetPassword } from "../../../store/actions/user";
import Header from "../../Main/Header";
import Form from "../../UI/Form";
import Errors from "../../UI/Form/Errors";
import Input from "../../UI/Form/Input";
import SmallFormContainer from "../../UI/Form/SmallFormContainer";
import { FormFooter } from "../../UI/Form/Styles";
import Submit from "../../UI/Form/Submit";
import Footer from "../Footer";
import { LoginWrapper } from "../Login/Styles";
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const [inputs, setInputs] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation('common');

  const handleInputChange = (value, name) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(null);
    setIsSuccess(false);
    setIsLoading(true);
    resetPassword(inputs)
      .then((res) => {
        setIsLoading(false);
        const { success, errors, error } = res.data;
        if (!success) {
          if (error?.message && !errors) setErrors([error?.message]);
          if (errors) setErrors(errors);
          return;
        }
        setIsSuccess(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  return (
    <LoginWrapper>
      <Header />
      {!isSuccess ? (
        <SmallFormContainer title={t('resetPassword.title')}>
          <p
            style={{
              margin: "1rem 0",
              textAlign: "center",
              lineHeight: "1.5",
              color: "#5A6978",
            }}
          >
            {t('resetPassword.content')}
          </p>
          {errors?.length > 0 && (
            <Errors>
              <h3>Form submission failed:</h3>
              {errors.map((error, i) => (
                <li key={i}>{error.msg}</li>
              ))}
            </Errors>
          )}
          <Form onSubmit={handleSubmit}>
            <Input
              onChange={handleInputChange}
              value={inputs?.email}
              name="email"
              auth
              placeholder={t('signIn.email')}
            />

            <Submit
              loading={isLoading ? 1 : null}
              size="large"
              title={t('resetPassword.button')}
            />
            <FormFooter auth>
              <p style={{ marginTop: "1rem" }}>
                <Link to="/login" title="Login">
                  {t('resetPassword.back')}
                </Link>
              </p>
            </FormFooter>
          </Form>
        </SmallFormContainer>
      ) : (
          <SmallFormContainer title="E-mail confirmation sent">
            <p
              style={{
                margin: "1rem 0",
                textAlign: "center",
                lineHeight: "1.5",
                color: "#5A6978",
              }}
            >
              We've sent you a confirmation to {inputs?.email}
            </p>
            <FormFooter auth>
              <p style={{ marginTop: "1rem" }}>
                <Link to="/login" title="Login">
                  {t('resetPassword.back')}
                </Link>
              </p>
            </FormFooter>
          </SmallFormContainer>
        )}
      <Footer />
    </LoginWrapper>
  );
};

export default ResetPassword;
