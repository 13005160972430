import React from "react";

import { EmptyDropdownWrapper } from "./styles";
import empty from "../../../../assets/media/empty.svg";

const EmptyDropdown = ({ companyName }) => {
  return (
    <EmptyDropdownWrapper>
      <img src={empty} alt="" />
      <p>
        You currently have no saved locations for
        <br /> <strong>{companyName}</strong>
      </p>
    </EmptyDropdownWrapper>
  );
};

export default EmptyDropdown;
