import axios from "axios";
import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import close from "../../assets/media/close.png";
import { API_URL } from "../../constants";
import {
  useUserDispatch,
  useUserState,
} from "../../store/context/user-context";
import { Wrapper, Cross, Inner, Error } from "./styles";
import CurrentSubscription from "./CurrentSubscription";
import OrderHistory from "./OrderHistory";
import withAuth from "../../utils/withAuth";
import { useTranslation } from 'react-i18next';
import Footer from '../Auth/Footer'
const Subscription = () => {
  const history = useHistory();
  const { data: user } = useUserState();
  const dispatch = useUserDispatch();
  const [isError, setIsError] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const { t, i18n } = useTranslation('common');

  const subscription = user?.companies[0]?.subscription;

  // Get the users subscription data if it does not exist yet
  useEffect(() => {
    if (!user || !subscription) {
      const cookies = new Cookies(),
        token = cookies.get("token");
      axios({
        url: `${API_URL}/subscription`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setIsFetched(true);
          const { success, companies } = res.data;
          if (!success) {
            setIsError(true);
            return;
          }
          if (companies)
            dispatch({
              type: "SET_COMPANIES",
              payload: companies,
            });
        })
        .catch((err) => {
          setIsError(true);
          setIsFetched(true);
          console.log("err", err);
        });
    }
  }, [user, subscription, dispatch]);

  return (
    <>
      <Wrapper>
        <Cross onClick={() => history.push("/")}>
          <img src={close} alt="Go Back" />
        </Cross>
        <h1>{t('subscription.title')}</h1>
        <Inner>
          {isFetched && !subscription && (
            <p>{t('subscription.noSub')}</p>
          )}
          {isError && isFetched && subscription && (
            <Error>{t('subscription.error')}</Error>
          )}
          <CurrentSubscription subscription={subscription} />
          {subscription && <OrderHistory />}
        </Inner>
      </Wrapper>
      <Footer />
    </>
  );
};

export default withAuth(Subscription);
