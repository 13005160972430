import React, { useState } from "react";
import Delete from "../UI/Delete";
import Table from "../UI/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableHeaderItem from "../UI/Table/TableHeaderItem";
import TableRow from "../UI/Table/TableRow";
import TableRowItem from "../UI/Table/TableRowItem";
import LocationSelector from "./LocationSelector";
import { ActiveStatus } from "./styles";
import User from "./User";
import { useTranslation } from 'react-i18next';

const GroupByUsers = ({
  locations,
  users,
  addUserToLocation,
  removeUserLocation,
  removeUser,
}) => {
  const [allUsers, setAllUsers] = useState(users);
  const { t, i18n } = useTranslation('common');

  const handleDeleteUser = (id) => {
    const user = allUsers.find((user) => user.id === id);
    const confirmation = window.confirm(
      `Are you sure you want to delete ${user.firstName} ${user.lastName} from your company?`
    );
    if (!confirmation) return;
    const newUsers = allUsers.filter((user) => user.id !== id);
    setAllUsers(newUsers);

    // Remove the user from parent
    removeUser(id);
  };

  // Tell the user they don't have any users to show
  if (!allUsers || allUsers?.length <= 0)
    return <p>{t('users.noUsers')}</p>;

  return (
    <Table>
      <TableHeader>
        <TableHeaderItem flex="0.3">{t('users.name')}</TableHeaderItem>
        <TableHeaderItem flex="0.3">{t('users.locations')}</TableHeaderItem>
        <TableHeaderItem flex="0.3">{t('users.status')}</TableHeaderItem>
        <TableHeaderItem flex="0.2"></TableHeaderItem>
      </TableHeader>
      {allUsers.map((user) => {
        const { id, photo, firstName, lastName, status } = user || {};
        const name = `${firstName} ${lastName}`;
        // Filter out locations where the user ID is set
        const userLocations =
          locations?.filter((location) => location.employees.includes(id)) ||
          [];
        return (
          <TableRow key={id}>
            <TableRowItem flex="0.3">
              <User img={photo} name={name} />
            </TableRowItem>
            <TableRowItem flex="0.3">
              <LocationSelector
                userId={id}
                locations={userLocations}
                allLocations={locations}
                removeUserLocation={removeUserLocation}
                addUserToLocation={addUserToLocation}
              />
            </TableRowItem>
            <TableRowItem flex="0.3">
              <ActiveStatus isActive={status === "Active"}>
                {status=== "Active" ? `${t('users.active')}`: status}
              </ActiveStatus>
            </TableRowItem>
            <TableRowItem flex="0.2" align="flex-end">
              <Delete onClick={() => handleDeleteUser(id)} />
            </TableRowItem>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default GroupByUsers;
