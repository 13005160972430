import styled from "styled-components";

export const DeleteWrapper = styled.button`
  background: #f5f5f5;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  transition: 0.25s all ease;
  img {
    width: 14px;
    height: 14px;
  }
  &:hover {
    background: #eaeaea;
  }
`;
