import React from "react";
import capitalize from "../../utils/capitalize";
import Table from "../UI/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableHeaderItem from "../UI/Table/TableHeaderItem";
import TableRow from "../UI/Table/TableRow";
import TableRowItem from "../UI/Table/TableRowItem";
import LocationSelector from "./LocationSelector";
import RoleSelector from "./RoleSelector";
import { ActiveStatus } from "./styles";
import User from "./User";
import { useTranslation } from 'react-i18next';

const GroupByLocations = ({
  users,
  locations,
  removeUserLocation,
  addUserToLocation,
  changeRole,
}) => {
  // Tell the user that they don't have any locations to show
  const { t, i18n } = useTranslation('common');
  if (!locations || locations?.length <= 0)
    return <p>You don't have any locations to show.</p>;

  return locations.map((location) => {
    const { id: locationId, name } = location;

    // Find user data from users
    const locationUsers = [];
    users.forEach((user) => {
      user.locations.forEach((location) => {
        if (location.id === locationId) locationUsers.push(user);
      });
    });

    return (
      <Table title={name} key={locationId}>
        <TableHeader>
          <TableHeaderItem flex="0.3">{t('users.name')}</TableHeaderItem>
          <TableHeaderItem flex="0.3">{t('users.role')}</TableHeaderItem>
          <TableHeaderItem flex="0.3">{t('users.locations')}</TableHeaderItem>
          <TableHeaderItem flex="0.3">{t('users.status')}</TableHeaderItem>
        </TableHeader>
        {/* Show a notice when there isn't any users in this location */}
        {locationUsers?.length <= 0 && (
          <p style={{ marginTop: "1rem" }}>
            You don't have any users in this location
          </p>
        )}
        {locationUsers?.length > 0 &&
          locationUsers.map((user) => {
            const { id, photo, firstName, lastName, status } = user;
            const name = `${firstName} ${lastName}`;

            // Get the correct user role from locations
            const { role } =
              user.locations.find((location) => location.id === locationId) ||
              "employee"; // Fallback 'employee' when something goes awry

            // Get the locations where the user is present
            const userLocations =
              locations.filter((location) => location.employees.includes(id)) ||
              [];

            // If the user doesn't have any locations, dont show them
            if (!userLocations || userLocations?.length <= 0) return null;
            return (
              <TableRow key={id}>
                <TableRowItem flex="0.3">
                  <User img={photo} name={name} />
                </TableRowItem>
                <TableRowItem flex="0.3">
                  <RoleSelector
                    role={role && capitalize(role)}
                    userId={id}
                    locationId={locationId}
                    changeRole={changeRole}
                  />
                </TableRowItem>
                <TableRowItem flex="0.3">
                  <LocationSelector
                    userId={id}
                    locations={userLocations}
                    allLocations={locations}
                    removeUserLocation={removeUserLocation}
                    addUserToLocation={addUserToLocation}
                  />
                </TableRowItem>
                <TableRowItem flex="0.3">
                  <ActiveStatus isActive={status === "Active"}>
                    {status === "Active" ? `${t('users.active')}` : status}
                  </ActiveStatus>
                </TableRowItem>
              </TableRow>
            );
          })}
      </Table>
    );
  });
};

export default GroupByLocations;
