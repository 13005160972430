import styled, { css } from "styled-components";

export const LocationSelectorWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  transition: 0.25s all ease;
  position: relative;
  justify-content: space-between;
  width: 115px;
  color: #474747;
  user-select: none;
  &:hover {
    span {
      background: #f9f9f9;
    }
  }
  span {
    transition: 0.25s all ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 4px;
    user-select: none;
  }
`;
export const LocationSelectorDropdown = styled.ul`
  position: absolute;
  top: 2rem;
  left: -4rem;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
  min-width: 250px;
  z-index: 1;
  cursor: default;
  h3 {
    padding: 0.75rem 1rem;
    color: #8a8a8a;
    font-size: 14px;
  }
  p {
    padding: 1rem;
    color: #000;
    font-size: 16px;
  }
`;
export const LocationSelectorDropdownItem = styled.li`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: default;
  &:nth-child(even) {
    background: #f9f9f9;
  }
  h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 1rem;
    max-width: 175px;
  }
`;

export const AddToLocation = styled.div`
  padding-top: 0.5rem;
  border-top: 1px solid #f9f9f9;
  cursor: default;
`;

export const AddToLocationSelectWrapper = styled.div`
  padding: 0 1rem;
`;

export const AddLocationButton = styled.button`
  border: none;
  padding: 0.5rem 2rem;
  margin: 1rem 0;
  border-radius: 4px;
  background: #2774CC;
  color: #fff;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      background: #ececec;
      color: #a3a3a3;
    `}
`;
