import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { getUser, logUserIn } from "../../../store/actions/user";
import {
  useUserDispatch,
  useUserState,
} from "../../../store/context/user-context";
import Header from "../../Main/Header";
import Form from "../../UI/Form";
import Errors from "../../UI/Form/Errors";
import Input from "../../UI/Form/Input";
import SmallFormContainer from "../../UI/Form/SmallFormContainer";
import { FormFooter, FormOptions } from "../../UI/Form/Styles";
import Submit from "../../UI/Form/Submit";
import Footer from "../Footer";
import { LoginWrapper } from "./Styles";
import { useTranslation } from 'react-i18next';

const Login = () => {
  const [inputs, setInputs] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useUserDispatch();
  const { data: user } = useUserState();
  const history = useHistory();
  const { t, i18n } = useTranslation('common');

  const handleInputChange = (value, name) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    logUserIn(inputs)
      .then((res) => {
        setIsLoading(false);
        const { success, errors } = res.data;
        if (!success) {
          if (errors) setErrors(errors);
          return;
        }
        const { companies, user, token } = res.data;

        // Set the cookies
        const cookies = new Cookies();

        // If user has opted to remember credentials, set expiry date to 14 days
        cookies.set("token", token, {
          path: "/",
          expires: inputs?.remember ? dayjs().add(10, "day").toDate() : null,
        });

        // Add user data to context
        dispatch({
          type: "SET_USER",
          payload: { companies, user },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  // Redirect the user when logged in
  useEffect(() => {
    if (user) {
      history.replace("/");
    } else {
      // Check if user has token
      const cookies = new Cookies(),
        token = cookies.get("token");
      // If has token, try to get the user data
      if (token) {
        getUser()
          .then((res) => {
            const { success, user, companies } = res.data;
            if (!success) return;
            dispatch({
              type: "SET_USER",
              payload: { user, companies },
            });
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  }, [user, history, dispatch]);

  return (
    <LoginWrapper>
      <Header />
      <SmallFormContainer title={t('signIn.title')}>
        {errors?.length > 0 && (
          <Errors>
            <h3>Form submission failed:</h3>
            {errors.map((error, i) => (
              <li key={i}>{error.msg}</li>
            ))}
          </Errors>
        )}
        <Form onSubmit={handleSubmit}>
          <Input
            onChange={handleInputChange}
            value={inputs?.email}
            name="email"
            auth
            placeholder={t('signIn.email')}
          />
          <Input
            onChange={handleInputChange}
            value={inputs?.password}
            name="password"
            type="password"
            auth
            placeholder={t('signIn.password')}
          />

          <Submit loading={isLoading ? 1 : null} size="large" title={t('signIn.sign-in')} />
          <FormOptions auth flex>
            <Input
              centered
              reversed
              type="checkbox"
              name="remember"
              label={t('signIn.rememberMe')}
              value={inputs?.remember}
              onChange={() =>
                setInputs((prev) => ({
                  ...prev,
                  remember: !inputs?.remember ?? false,
                }))
              }
            />
            <Link to="/reset-password" title="I forgot my password">
              {t('signIn.forgotPassword')}
            </Link>
          </FormOptions>
          <FormFooter auth>
            <p>
              {t('signIn.newIn')}
              <Link to="/register" title="Register an account">
                {t('signIn.registerNow')}
              </Link>
            </p>
          </FormFooter>
        </Form>
      </SmallFormContainer>
      <Footer />
    </LoginWrapper>
  );
};

export default Login;
