import styled, { css } from "styled-components";

export const Inner = styled.div`
  margin-top: 2rem;
  margin-bottom: 6rem;
`;

export const UserWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  img {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #f9f9f9;
    object-fit: cover;
  }
  span {
    display: inline-block;
    width: 100%;
    color: #474747;
    font-size: 16px;
    margin-left: 0.75rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 1rem;
  }
`;

export const RoleSelectorWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  transition: 0.25s all ease;
  position: relative;
  justify-content: space-between;
  width: 115px;
  color: #474747;
  user-select: none;
  &:hover {
    span {
      background: #f9f9f9;
    }
  }
  span {
    transition: 0.25s all ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 4px;
    user-select: none;
  }
`;

export const RoleSelectorDropdown = styled.ol`
  position: absolute;
  top: 2rem;
  left: -1rem;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
  min-width: 150px;
  z-index: 1;
`;

export const RoleSelectorDropdownItem = styled.li`
  padding: 1rem;
  background: #fff;
  cursor: pointer;
  transition: 0.25s all ease;
  color: #474747;

  &:hover {
    background: #f0f4ff;
  }
  &:nth-child(1) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:nth-last-child(1) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  ${({ isCurrent }) =>
    isCurrent &&
    css`
      color: #fff;
      background: #2774CC;
      cursor: not-allowed;
      &:hover {
        background: #2774CC;
      }
    `}
`;

export const ActiveStatus = styled.div`
  font-size: 16px;
  color: #a8a8a8;
  ${({ isActive }) =>
    isActive &&
    css`
      color: #2ab646;
    `}
`;
