import styled, { css } from "styled-components";
import checkmark from "../../../assets/media/checkbox.png";

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
  position: relative;
  ${(props) =>
    props.type === "checkbox" &&
    css`
      margin-bottom: 0rem;
      input,
      label {
        cursor: pointer;
      }
      label {
        padding-left: 5px;
      }
    `}
  input {
    font-family: "Euclid-Regular", sans-serif;
    font-size: 16px;
    &::placeholder {
      color: #8190a5;
      font-family: "Euclid-Regular";
    }
  }
  ${(props) =>
    props.default &&
    css`
      input {
        padding: 1rem;
        border-radius: 4px;
        border: 1px solid #8492a6;
      }
    `}
  ${(props) =>
    props.auth &&
    css`
      max-width: 350px;
      width: 100%;
      input {
        padding: 1rem;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #8492a6;
      }
    `}
  ${(props) =>
    props.bottomBorder &&
    css`
      label {
        margin-bottom: 1rem;
      }
      input {
        border: none;
        border-bottom: 1px solid #969faa;
        &:focus {
          border-bottom: 1px solid #2774CC;
        }
      }
    `}
  ${(props) =>
    props.centered &&
    css`
      display: flex;
      align-items: center;
    `}

  label {
    color: #5a6978;
    margin-right: 0.75rem;
  }
  ${(props) =>
    props.reversed &&
    css`
      flex-direction: row-reverse;
      margin-bottom: 0;
    `}
  
  ${(props) =>
    props.flex &&
    css`
      display: grid;
      grid-template: "header header";
      grid-template-columns: 140px 200px;
      @media only screen and (max-width: 374px) {
        display: block;
        label {
          display: block;
        }
      }
      @media only screen and (min-width: 462px) {
        grid-template-columns: 200px 200px;
      }
    `}
    ${(props) =>
    props.block &&
    css`
          display: block
        `}
    ${(props) =>
    props.inline &&
    css`
      label {
        display: inline-block;
        width: 165px;
        margin-bottom: 0.5rem;
        @media only screen and (min-width: 557px) {
          margin-bottom: 0;
        }
      }
    `}
    ${(props) =>
    props.default &&
    props.bottomBorder &&
    css`
      input {
        border-radius: 0px;
      }
    `}
    ${(props) => props.typeSecond &&
    css`
        input {
          margin-top: 20px;
          border: solid 1px #8492A6;
          border-radius: 4px;
          width: 262px;
          height: 44px;
          padding: 2px 8px
        }
      `
  }
`;

export const SelectWrapper = styled.div`
  margin: 1.5rem 0;
  max-width: 264px;
  ${({ noMargin }) => noMargin && `margin: 0;`}
  ${({ autoMargin }) => autoMargin && `margin: auto;`}
`;

export const SubmitWrapper = styled.div`
  input {
    padding: 0.75rem;
    border-radius: 5px;
    border-color: transparent;
    background-color: #2774CC;
    font-family: "Euclid-Regular";
    color: #fff;
    ${(props) =>
    props.white &&
    css`
        background: transparent;
        border: solid 1px #fff;
        transition: all 0.3s;
        &:hover {
          background: #4392BB;
        }
      `
  }
    ${(props) =>
    props.cancel &&
    css`
        background-color: transparent;
        color: #929eb1;
        border: 1px solid #929eb1;
      `}
    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }
  ${({ size }) => {
    if (size === "small") {
      return css`
        max-width: 150px;
        width: 100%;
        input {
          width: 100%;
        }
      `;
    }
    if (size === "medium") {
      return css`
        max-width: 190px;
        width: 100%;
        input {
          width: 100%;
        }
      `;
    }
    if (size === "large") {
      return css`
        max-width: 350px;
        width: 100%;
        input {
          width: 100%;
          padding: 1rem;
        }
        @media only screen and (min-width: 768px) {
          input {
            font-size: 18px;
          }
        }
      `;
    }
  }}
  ${({ margin }) => {
    if (margin === "mt-5") {
      return css`
        margin-top: 5rem;
        @media only screen and (max-width: 374px) {
          margin-top: 2rem;
        }
      `;
    }
    if (margin === "mt-4") {
      return css`
        margin-top: 4rem;
      `;
    }
    if (margin === "mt-3") {
      return css`
        margin-top: 3rem;
      `;
    }
    if (margin === "mt-2") {
      return css`
        margin-top: 2rem;
      `;
    }
    if (margin === "mt-1") {
      return css`
        margin-top: 1rem;
      `;
    }
  }}
  ${(props) =>
    props.img &&
    css`
      input {
        border: none;
        background-color: transparent;
        max-width: 45px;
        padding: 0;
        @media only screen and (min-width: 576px) {
          max-width: 65px;
        }
      }
    `}
    ${(props) =>
    props.absolute &&
    css`
      width: max-content !important;
      position: absolute;
      right: -50px;
      top: 5px;
      @media only screen and (min-width: 576px) {
        right: -80px;
        top: -7px;
      }
    `}
`;

export const FormOptions = styled.div`
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
    `}

  ${(props) =>
    props.auth &&
    css`
      max-width: 350px;
      width: 100%;
      a {
        text-decoration: none;
        color: #5a6978;
        font-size: 14px;
      }
    `}
    label {
    font-size: 14px;
  }
  input {
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
    &:checked {
      background-color: #2774CC;
      background-image: url(${checkmark});
      background-position: 50% 50%;
      background-size: 9px;
      background-repeat: no-repeat;
    }
  }
  @media only screen and (max-width: 374px) {
    label {
      font-size: 12px;
    }
    a {
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 768px) {
    input {
      width: 22px;
      height: 22px;
      &:checked {
        background-size: 12px;
      }
    }
  }
`;

export const FormFooter = styled.div`
  ${(props) =>
    props.auth &&
    css`
      margin-top: 0.5rem;
      text-align: center;
      p {
        color: #343f4b;
      }
      a {
        font-family: "Euclid-Bold";
        font-weight: 700;
        text-decoration: none;
        color: #343f4b;
        margin-left: 0.5rem;
      }
    `}
`;

export const ShowPasswordButton = styled.button`
  position: absolute;
  top: 0.65rem;
  right: 0.5rem;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  height: 20px;
  width: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
