import React, { useEffect, useState } from "react";
import whiteLogo from "../../assets/media/white_logo.svg";
import {
  SignInText,
  Logo,
  LogoTitle,
  LogoWrapper,
  BackgroudWrapper,
  FlexWrapper,
  SmallText,
} from "../Policy/styles";
import Form from "../UI/Form";
import Select from "../UI/Form/Select";
import Input from "../UI/Form/Input";

import Submit from "../UI/Form/Submit";
import axios from "axios";
import { API_URL } from "../../constants";
import Creatable from "../UI/Form/Creatable";
import { getUser, logUserIn } from "../../store/actions/user";
import {
  useUserDispatch,
  useUserState,
} from "../../store/context/user-context";
import {
  useLocation
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t, i18n } = useTranslation('common');

  const [inputs, setInputs] = useState(null);
  const [inputs2, setInputs2] = useState(null);
  const dispatch = useUserDispatch();

  const [locationOptions, setLocationOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(false)
  const [isFetched, setIsFetched] = useState(false);
  const [currentName, setCurrentName] = useState("");
  const [availableUsers, setAvailableUsers] = useState(false)
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [newer, setNewer] = useState(false)
  const [timer, setTimer] = useState(5);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [errors, setErrors] = useState(null);
  /*
    useEffect(() => {
      axios({
        url: `${API_URL}/geoFences`,
        method: "GET",
      }).then((res) => {
        setIsFetched(true);
        if (res.data.locations.length > 0) {
          console.log('aaa', res.data.locations)
          setLocationOptions(res.data.locations);
        }
      });
    }, []);
    */


  const handleInputChange = (value, name) => {
    setInputs2((prev) => ({ ...prev, [name]: value }));
  };
  const setLocation = (e) => {
    e.preventDefault()
    setIsLoading(true)

    if (inputs !== null && inputs.location) {
      window.location.href = "/record-location/?id=" + inputs?.location?.value + "&name=" + inputs?.location?.label
    }
    setIsLoading(false)

  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  useEffect(() => {
    const locationId = query.get('id')
    const locationName = query.get('name')
    if (locationId && locationName) {
      setCurrentLocation({ id: locationId, name: locationName })
    }
    else {
      setCurrentLocation(false)
    }
  }, []);

  useEffect(() => {
    if (currentLocation.id) {
      axios({
        url: `${API_URL}/locationQuests`,
        params: {
          id: currentLocation.id
        },
        method: "GET",
      }).then((res) => {
        if (res.data.users.length > 0) {
          setAvailableUsers(res.data.users);
        } else {
          setAvailableUsers([]);

        }

      });
    }

  }, [currentLocation, loadingScreen])


  const getSuggestions = async () => {
    const suggestions = [];
    if (availableUsers) {
      const loggedUsers = availableUsers;

      for (const user of loggedUsers) {
        if (user.label.toLowerCase().indexOf(currentName.toLowerCase()) > -1) {
          suggestions.push(user);
        }
      }
    }
    return await suggestions;
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    let isNew = inputs?.name.__isNew__ ? true : false;

    if (isNew) {
      setNewer(true)
      setIsLoading(true);
      axios({
        url: `${API_URL}/register-to-location`,
        method: "POST",
        data: {
          location: currentLocation,
          ...inputs,
        },
      }).then((res) => {
        setIsLoading(false);
        setLoadingScreen(true);
        setInputs(null)

      });
    }
    else {
      setIsLoading(true);
      setNewer(false)

      axios({
        url: `${API_URL}/register-to-location`,
        method: "DELETE",
        data: {
          location: currentLocation,
          ...inputs,
        },
      }).then((res) => {
        setIsLoading(false);
        setLoadingScreen(true);
        setInputs(null)
      });
    }
  };
  const countDown = () => {
    let timeleft = 5;
    let downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        setTimer(5)
        setLoadingScreen(false)
        return;
      }
      timeleft -= 1;
      setTimer(timeleft)
    }, 1000);
  };

  useEffect(() => {
    if (loadingScreen) {
      countDown();
    }
  }, [loadingScreen]);

  const redirect = () => {
    window.location.href = "/record-location"
  }

  const logIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    logUserIn(inputs2)
      .then((res) => {
        setIsLoading(false);
        const { success, errors } = res.data;
        if (!success) {
          if (errors) setErrors(errors);
          return;
        }
        const { companies, user, token } = res.data;
        dispatch({
          type: "SET_USER",
          payload: { companies, user },
        });
        const locations = []
        for (const comp of companies) {
          for (const loc of comp.locations) {
            locations.push({ "label": loc.name, value: loc.id })
          }
        }
        setIsLoggedIn(true)
        //{label: 'Elmia', value: 'jjM15yOC55LwY68QPIzp'}
        setLocationOptions(locations)
        setLoadingScreen(false)
        setIsFetched(true)
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <BackgroudWrapper>
        {!isLoggedIn ? (
          <FlexWrapper>

            <Form onSubmit={logIn}>

              <Input
                onChange={handleInputChange}
                value={inputs2?.email}
                name="email"
                auth
                placeholder={t('signIn.email')}
              />
              <Input
                onChange={handleInputChange}
                value={inputs2?.password}
                name="password"
                type="password"
                auth
                placeholder={t('signIn.password')}
              />
              <Submit loading={isLoading ? 1 : null} size="large" title={t('signIn.sign-in')} />

            </Form>
          </FlexWrapper>

        ) :
          <>
            {!loadingScreen ? (
              <FlexWrapper>
                {!currentLocation ? (
                  <LogoWrapper>
                    <Logo src={whiteLogo} />
                    <LogoTitle>JobPals</LogoTitle>


                    {!isFetched ? (
                      <SignInText>Loading available locations...</SignInText>
                    ) :

                      <Form onSubmit={setLocation}>
                        <SignInText>Select location for manual user registering</SignInText>
                        <Select
                          noMargin
                          autoMargin
                          placeholder="Available locations"
                          name="location"
                          options={locationOptions}
                          value={inputs?.location}
                          onChange={(val) =>
                            setInputs((p) => ({ ...p, location: val }))
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            maxWidth: "264px",
                          }}
                        ></div>
                        <Submit white loading={isLoading} title="Set location"></Submit>

                      </Form>
                    }
                  </LogoWrapper>
                ) : (

                  <LogoWrapper>
                    <Logo src={whiteLogo} />
                    <LogoTitle>JobPals</LogoTitle>
                    <SignInText>{`Log in or out from ${currentLocation.name} by entering your name`} </SignInText>
                    <Form onSubmit={handleSubmit}>
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                          maxWidth: "264px",
                          flexWrap: "wrap"
                        }}
                      >
                        <Creatable
                          promiseOptions={getSuggestions}
                          onChange={(val) =>
                            setInputs((p) => ({ ...p, name: val }))
                          }
                          value={inputs?.name}
                          placeholder="Name"
                          onInputChange={(e) => setCurrentName(e)}
                        />
                        <div style={{ marginTop: "20px", width: "100%" }}></div>
                        <Input
                          auth
                          onChange={(val) =>
                            setInputs((p) => ({ ...p, company: val }))
                          }
                          value={inputs?.company}
                          placeholder="Company"
                        />
                        <Input
                          auth
                          onChange={(val) =>
                            setInputs((p) => ({ ...p, parking: val }))
                          }
                          value={inputs?.parking}
                          placeholder="Car number"
                        />

                      </div>
                      <Submit loading={isLoading} white title="Submit"></Submit>

                    </Form>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        maxWidth: "264px",
                      }}
                    ></div>
                    <SmallText onClick={redirect}>Change location</SmallText>
                  </LogoWrapper>
                )}
              </FlexWrapper>
            ) : <FlexWrapper>
              <LogoWrapper>
                <Logo src={whiteLogo} />
                <LogoTitle>JobPals</LogoTitle>
                <SignInText>
                  You have successfully logged {newer ? "in at" : "out of"}{" "}
                  {inputs?.location?.label} <br />

                </SignInText>
                <SignInText>Redirecting to first screen in {timer}</SignInText>
              </LogoWrapper>

            </FlexWrapper>}
          </>
        }

      </BackgroudWrapper>
    </>
  );
};

export default Contact;
