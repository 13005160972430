import React from "react";
import { TableHeaderItemWrapper } from "./styles";

const TableHeaderItem = ({ children, flex, align }) => {
  return (
    <TableHeaderItemWrapper flex={flex} align={align}>
      {children}
    </TableHeaderItemWrapper>
  );
};

export default TableHeaderItem;
