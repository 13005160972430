import styled from "styled-components";

export const Options = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 1.5rem;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  input {
    width: unset;
  }
  label {
    @media only screen and (max-width: 350px) {
      display: block;
      margin-bottom: 1rem;
    }
  }
  @media only screen and (min-width: 768px) {
    display: flex;
    width: 720px;
  }
`;
