import axios from "axios";
import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../constants";
import Form from "../../UI/Form";
import Select from "../../UI/Form/Select";
import Submit from "../../UI/Form/Submit";
import Date from "../../UI/Form/Date";
import Errors from "../../UI/Form/Errors";
import { useTranslation } from 'react-i18next';

const TimeReport = ({ id, constructionSite }) => {
  const [inputs, setInputs] = useState(null);
  const [errors, setErrors] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isMultiInp, setIsMulti] = useState(true)
  const [employeeOptions, setEmployeeOptions] = useState(null);
  const { t, i18n } = useTranslation('common');

  // TODO: Get users for company
  useEffect(() => {
    // Check if ID exists, if not, notify user
    if (!id) {
      setIsFetched(true);
      return;
    }
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/companyUsers`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { id: id },
    })
      .then((res) => {
        setIsFetched(true);
        const { success, users } = res.data;
        if (!success) {
          setIsSuccess(false);
          return;
        }
        setIsSuccess(true);
        setEmployees(users);
      })
      .catch((err) => {
        setIsFetched(true);
        console.log("err", err);
      });
  }, [id]);

  // Clear endDate value when startDate is earlier than endDate
  const { startDate, endDate } = inputs || {};
  useEffect(() => {
    if (startDate && endDate) {
      if (endDate < startDate) {
        setInputs((p) => ({ ...p, endDate: null }));
      }
    }
  }, [startDate, endDate]);


  // Set employees to options
  useEffect(() => {
    if (employees) {
      const options = employees.map((emp) => {
        return {
          label: `${emp?.firstName} ${emp?.lastName}`,
          value: emp?.id,
        };
      });
      if (constructionSite) {
        options.unshift({ value: "subcontractors", label: "Subcontractors" })
      }
      options.unshift({ value: "active", label: "Users currently at location" })
      options.unshift({ value: "all", label: "All employees" })
      setEmployeeOptions(options);
    }
  }, [employees]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/timeReport`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',//inputs.docType && inputs.docType.value === "XML" ? 'application/xml' : 'blob',
      data: {
        ...inputs,
        companyId: id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
    })
      .then((res) => {
        const { success } = res.data;
        setIsLoading(false);
        if (success === false) {
          return;
        }
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        if (inputs.docType && inputs.docType.value === "XML") {
          link.setAttribute('download', 'Time_Report.xml'); //or any other extension
        }
        else {
          link.setAttribute('download', 'Time_Report.pdf'); //or any other extension
        }
        document.body.appendChild(link);
        link.click();


      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  return (
    <>
      <h2>{t('timereport.title')}</h2>
      {!id && "Could not find employees."}
      {isFetched && !isSuccess && (
        <>
          <br />
          Unable to create a time report.
        </>
      )}
      {errors?.length > 0 && (
        <Errors>
          <h3>Form submission failed:</h3>
          {errors.map((error, i) => (
            <li key={i}>{error.msg}</li>
          ))}
        </Errors>
      )}
      {isFetched && isSuccess ? (
        <Form onSubmit={handleSubmit}>
          <Select
            placeholder={t('timereport.selectUsers')}
            name="name"
            isMulti={isMultiInp}
            options={employeeOptions}
            value={inputs?.employees}
            onChange={(val) => setInputs((p) => ({ ...p, employees: val }))}
          />
          <div style={{ marginBottom: "1.5rem" }}>
            <Date
              date={inputs?.startDate}
              placeholder={t('timereport.startDate')}
              onChange={(date) => setInputs((p) => ({ ...p, startDate: date }))}
            />
          </div>
          <div style={{ marginBottom: "1.5rem" }}>
            <Date
              date={inputs?.endDate}
              disabled={!inputs?.startDate}
              minDate={inputs?.startDate}
              placeholder={t('timereport.endDate')}
              onChange={(date) => setInputs((p) => ({ ...p, endDate: date }))}
            />
          </div>
          {constructionSite ? (
            <Select
              placeholder={t('timereport.documentType')}
              name="docType"
              options={[
                { value: "PDF", label: "PDF" },
                { value: "XML", label: "XML" }
              ]}
              value={inputs?.docType ? inputs?.docType : "PDF"}
              onChange={(val) => setInputs((p) => ({ ...p, docType: val }))}
            />
          ) : null}
          <Submit
            loading={isLoading ? 1 : null}
            title={t('timereport.generate')}
            size="medium"
            margin="mt-4"
          />
        </Form>
      ) : isFetched && !isSuccess ? null : (
        "Loading..."
      )}
    </>
  );
};

export default TimeReport;
