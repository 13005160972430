import styled, { css } from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 1;
  ${(props) =>
    props.transparent &&
    css`
      background-color: rgba(52, 63, 75, 0.29);
    `}
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      padding: 0;
    `}
`;

export const Close = styled.div`
  cursor: pointer;
  position: fixed;
  top: 25px;
  right: 10px;
  width: 44px;
  height: 44px;
  @media only screen and (min-width: 557px) {
    top: 58px;
    right: 60px;
  }
`;

export const SideModalWrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background-color: rgba(52, 63, 75, 0.29);
  z-index: 3;
`;

export const SideModalInnerWrapper = styled.div`
  min-height: 100vh;
  padding: 2rem 1rem;
  background-color: #fff;
  margin-left: auto;
  h2 {
    font-family: "Euclid-Medium", sans-serif;
    color: #47525e;
    font-size: 28px;
    margin-bottom: 3rem;
    font-weight: 500;
  }
  @media only screen and (min-width: 414px) {
    max-width: 90%;
  }
  @media only screen and (min-width: 557px) {
    margin-bottom: 5rem;
    padding: 9rem 2rem 2rem 4rem;
  }
  @media only screen and (min-width: 768px) {
    max-width: 600px;
  }
`;
