import styled from "styled-components";

export const OnlyMobile = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;
export const OnlyDesktop = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`;
export const Background = styled.div`
  ${({ bg }) => bg && `background: ${bg};`}
`;
export const InvalidSubscription = styled.div`
  padding: 1rem;
  font-size: 18px;
  a {
    display: flex;
    padding: 1rem;
    margin-top: 1rem;
  }
`;
