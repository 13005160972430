import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 1rem;
  max-width: 1400px;
  margin: 0 auto;
  min-height: calc(100vh - 190px);
`;

export const Close = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 992px) {
    top: 6rem;
    right: 10rem;
  }
`;
export const Title = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  h1 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    max-width: 550px;
    line-height: 1.4;
  }
  @media (min-width: 992px) {
    margin-top: 6rem;
  }
`;

export const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
