import React from "react";
import { InputWrapper, ShowPasswordButton } from "./Styles";

const Input = (props) => {
  return (
    
    <InputWrapper
      centered={props.centered}
      typeSecond={props.typeSecond}
      auth={props.auth}
      reversed={props.reversed}
      bottomBorder={props.bottomBorder}
      flex={props.flex}
      default={props.default}
      inline={props.inline}
      showPassword={props.showPassword}
      type={props.type}
      block={props.block}
    >
      {props.label && (
        <label htmlFor={props.name} title={props.label}>
          {props.label}
        </label>
      )}
      <input
        disabled={props.disabled}
        min="0"
        id={props.name}
        name={props.name}
        value={props.value || ""}
        type={props.type || "text"}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value, props.name)}
        block={props.block}

      />
      {props.showPassword && (
        <ShowPasswordButton type="button" onClick={props.onShowPassword}>
          {props.showPasswordIcon}
        </ShowPasswordButton>
      )}
    </InputWrapper>
  );
};

export default Input;
