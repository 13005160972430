import styled, { css } from "styled-components";

export const TableWrapper = styled.div`
  position: relative;
  @media (max-width: 700px) {
    overflow-x: auto;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
  min-width: 700px;
`;

export const TableTitle = styled.h3`
  font-size: 16px;
  color: #383838;
  margin: 1rem 0;
  width: 100%;
`;

export const TableHeaderWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding: 1rem;
`;

export const TableHeaderItemWrapper = styled.div`
  ${({ flex }) => css`
    flex: ${flex ? flex : "1"};
  `}
  color: #C0CDE9;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
`;

export const TableRowWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  background: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
`;

export const TableRowItemWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ align }) =>
    align &&
    css`
      justify-content: ${align};
    `};
  ${({ flex }) => css`
    flex: ${flex ? flex : "1"};
  `}
  width: 100%;
`;
