import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  useUserDispatch,
  useUserState,
} from "../../store/context/user-context";
import withAuth from "../../utils/withAuth";
import Footer from "../Auth/Footer";
import { Options } from "../Location/styles";
import Header from "../Main/Header";
import RegistrateCompany from "../Main/RegistrateCompany";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import useModal from "../UI/Modal/useModal";
import { InnerWrapper, Wrapper } from "./styles";
import { useTranslation } from 'react-i18next';

const Onboarding = () => {
  const { data: user } = useUserState();
  const dispatch = useUserDispatch();
  const { isShowing, toggle } = useModal();
  const history = useHistory();
  const { t, i18n } = useTranslation('common');

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove("token");
    dispatch({ type: "SET_USER", payload: null });
  };

  // Send the user to front page if they already have companies

  useEffect(() => {
    if (user?.companies && user?.companies?.length > 0) {
      history.replace("/");
    }
  }, [user, history]);

  return (
    <>
      <Header />
      <Wrapper>
        <h2>
          {user?.user?.firstName ?  `${t('onboarding.hi')}  ${user?.user?.firstName}!` : `${t('onboarding.hi')} !`}
        </h2>
        <InnerWrapper>
          <p>{t('onboarding.regText')}</p>
          <p>{t('onboarding.regText2')}</p>
        </InnerWrapper>
        <Options relative>
          <Button title={t('onboarding.cancel')} cancel onClick={handleLogout} />
          <Button title={t('onboarding.registerCompany')} onClick={toggle} />
        </Options>
      </Wrapper>
      <Footer />
      {isShowing && (
        <Modal isShowing={isShowing} hide={toggle}>
          <RegistrateCompany toggleModal={toggle} />
        </Modal>
      )}
    </>
  );
};

export default withAuth(Onboarding);
