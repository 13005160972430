import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
`;
export const PositionSelectorHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  width: 100%;
  padding: 1rem;
  @media (min-width: 992px) {
    display: inline-flex;
    max-width: 900px;
    justify-content: space-between;
  }
`;
export const PositionSelectorFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90px;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background-color: #2774CC;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 1rem;
    width: 200px;
  }
`;
