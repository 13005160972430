import React from "react";
import { SubmitWrapper } from "./Styles";

const Submit = (props) => {
  const { id, title, size, margin, img, absolute, loading, white } = props || {};
  return (
    <SubmitWrapper
      loading={loading ? 1 : null}
      img={img}
      absolute={absolute}
      size={size}
      white={white ? true : null}
      margin={margin}
    >
      {img ? (
        <input type="image" src={img} alt="" />
      ) : (
          <input
            id={id}
            disabled={loading}
            type="submit"
            value={!loading ? title : "..."}
            onClick={props.onClick}
          />
        )}
    </SubmitWrapper>
  );
};

export default Submit;
