import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 28px;
    color: #47525e;
    width: 100%;
  }
`;

export const DetailsWrapper = styled.div`
  width: 100%;
  margin: 3rem 0;
  form {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const FormWrapper = styled.div`
 display: block
  @media only screen and (min-width: 768px) {
    flex: 0 0 50%;
    label {
      font-size: 18px;
    }
  }
`;

export const MapWrapper = styled.div`
  flex: 0 0 100%;
  @media only screen and (min-width: 768px) {
    flex: 0 0 50%;
  }
`;

export const LocationButton = styled.div`
  background: rgba(255, 255, 255, 0.8);
  color: #2774CC;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  font-weight: 700;
  border: none;
  position: absolute;
  right: 10px;
  bottom: 20px;
  font-family: "Euclid-Regular", sans-serif;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid #f9f9f9;
  transition: 0.25s all ease;
  &:hover {
    background: #fff;
  }
`;

export const CurrentLocation = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #5a6978;
  font-family: "Euclid-Regular";
  font-size: 18px;
  max-width: 200px;
  line-height: 1.5;
`;
