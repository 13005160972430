import React from "react";
import Button from "../UI/Button";
import Map from "./Map";
import { Header, Options, Wrapper } from "./styles";
import { useTranslation } from 'react-i18next';

const Location = ({
  onClose,
  currentLocation,
  setCurrentLocation,
  setCurrentRadius,
  presetRadius,
}) => {
  const { t, i18n } = useTranslation('common');

  return (
    <Wrapper>
      <Header>
        <h2>{t('companyDetails.choose')}</h2>
      </Header>
      <Map
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        setCurrentRadius={setCurrentRadius}
        presetRadius={presetRadius}
      />
      <Options>
        {/*   <Button title="Cancel" cancel size="large" onClick={onClose} /> */}
        <Button title={t('location.save')} size="large" onClick={onClose} />
      </Options>
    </Wrapper>
  );
};

export default Location;
