import React from "react";
import { Wrapper } from "./Styles";

const Button = (props) => {
  const { type, cancel, size, title, onClick, transparent, isDisabled } =
    props || {};

  return (
    <Wrapper
      size={size}
      cancel={cancel}
      transparent={transparent}
      isDisabled={isDisabled ? 1 : null}
    >
      <button type={type || "button"} onClick={onClick} disabled={isDisabled}>
        {title ? title : null}
      </button>
    </Wrapper>
  );
};

export default Button;
