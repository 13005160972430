import React from "react";
import { SmallFormWrapper, SmallFormInner } from "./FormStyles";

const SmallFormContainer = (props) => {
  return (
    <SmallFormWrapper>
      <SmallFormInner title={props.title}>
        {props.title && <h2>{props.title}</h2>} {props.children}
      </SmallFormInner>
    </SmallFormWrapper>
  );
};

export default SmallFormContainer;
