import axios from "axios";
import Cookies from "universal-cookie";
import { API_URL } from "../../constants";

export const signUpUser = (data) => {
  return axios({
    url: `${API_URL}/sign-up`,
    method: "POST",
    data,
  });
};

export const logUserIn = (data) => {
  return axios({
    url: `${API_URL}/sign-in`,
    method: "POST",
    data,
  });
};

export const getUser = () => {
  const cookies = new Cookies(),
    token = cookies.get("token");

  return axios({
    url: `${API_URL}/user`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const resetPassword = (email) => {
  return axios({
    url: `${API_URL}/reset-password`,
    method: "POST",
    data: email,
  });
};
