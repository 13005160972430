import styled, { css } from "styled-components";

export const Outer = styled.div`
  position: absolute;
  top: 83px;
  z-index: 1;
  background: #fff;
  width: 100%;
  height: calc(100vh - 83px);
  display: none;
  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `}
  @media (min-width: 992px) {
    display: block;
    position: relative;
    width: 300px;
    min-width: 300px;
    top: 0;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  border-right: 1px solid #d3dce6;
  width: 100%;
`;

export const DrawerCollection = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const DrawerItemWrapper = styled.li`
  padding: 1.5rem 1rem;
  color: #48525e;
  transition: 0.25s all ease;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    &:hover {
      background: #eceff5;
    }
  }
  @media (min-width: 992px) {
    position: relative;
  }
`;

export const LocationItemWrapper = styled(DrawerItemWrapper)`
  background: #fff;
  border: none;
  border-bottom: 1px solid #d3dce6;
  font-family: "Euclid-Regular", sans-serif;
  &:hover {
    background: #eceff5;
  }
`;

export const SideDrawerHeader = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  h3 {
    color: #95a1ad;
  }
`;

export const LinkWrapper = styled.div`
  a {
    border-bottom: 1px solid #d3dce6;
    padding: 2rem 1rem;
    font-size: 18px;
    color: #48525e;
    font-weight: 500;
    text-decoration: none;
    width: 100%;
    display: flex;
    @media only screen and (min-width: 1200px) {
      min-width: 300px;
      &:hover {
        background-color: #eceff5;
      }
    }
  }
`;
