import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import ResetPassword from "./components/Auth/ResetPassword";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import Contact from "./components/Contact";
import Documents from "./components/Documents";
import Invite from "./components/Invite";
import Main from "./components/Main";
import LocationCreator from "./components/Main/LocationCreator";
import Onboarding from "./components/Onboarding";
import Policy from "./components/Policy";
import Subscription from "./components/Subscription";
import Terms from "./components/Terms";
import Users from "./components/Users";
import RecordLocation from "./components/RecordLocation";
import companyInfo from "./components/CompanyInfo";
import timetables from "./components/Timetables";

import { AppProvider } from "./store/context/app-context";
import { UserProvider } from "./store/context/user-context";

const App = () => {
  return (
    <AppProvider>
      <UserProvider>
        <Router>
          <Route exact path="/" component={Main} />
          <Route path="/login" component={Login} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/register" component={Register} />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/cart" component={Cart} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/subscription" component={Subscription} />
          <Route path="/users" component={Users} />
          <Route path="/invite" component={Invite} />
          <Route path="/location" component={LocationCreator} />
          <Route path="/documents" component={Documents} />
          <Route path="/terms" component={Terms} />
          <Route path="/policy" component={Policy} />
          <Route path="/contact" component={Contact} />
          <Route path="/record-location" component={RecordLocation} />
          <Route path="/company-info" component={companyInfo} />
          <Route path="/timetables" component={timetables} />

        </Router>
      </UserProvider>
    </AppProvider>
  );
};

export default App;
