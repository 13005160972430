import styled from "styled-components";

export const Wrapper = styled.div`
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 83px);
  overflow: auto;
  @media (min-width: 992px) {
    height: auto;
    max-width: 300px;
    left: calc(100% + 1px);
  }
`;

export const DropdownNavigator = styled.button`
  display: inline-flex;
  width: 100%;
  background: #f9f9f9;
  padding: 0.75rem 1rem;
  margin-bottom: 0.25rem;
  border: none;
  align-items: center;
  user-select: none;
  justify-content: flex-start;
  span {
    width: 60px;
    min-width: 60px;
    justify-content: flex-start;
    margin-left: 1rem;
    font-size: 15px;
    align-items: center;
    &:hover {
      background: transparent;
    }
  }
  img {
    transform: rotate(90deg);
  }
`;

export const EmptyDropdownWrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 185px;
  margin: 0 auto;
  p {
    margin-top: 0.75rem;
    text-align: center;
    color: #48525e;
    font-size: 16px;
    line-height: 1.3;
    strong {
      font-weight: 700;
    }
  }
`;
