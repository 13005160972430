import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import mapStyles from "../../Main/Map/mapStyles";
import locationIcon from "../../../assets/media/locationIcon.png";
import { Options } from "./styles";
import Input from "../../UI/Form/Input";
import { GOOGLE_MAPS_API_KEY } from "../../../constants";

Geocode.setApiKey("AIzaSyDTr6qoLLDs4Qxhwf7GfvXiwX9aM-uPAtY");

const mapContainerStyle = {
  height: "calc(100vh - 200px)",
  width: "100%",
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
};
const libraries = ["places"];

const Map = ({
  currentLocation,
  setCurrentLocation,
  setCurrentRadius,
  presetRadius,
}) => {
  const [marker, setMarker] = useState(currentLocation ?? null);
  const [center, setCenter] = useState({ lat: 55.8502712, lng: 12.9147853 });
  const [inputs, setInputs] = useState(
    presetRadius ? { radius: presetRadius } : null
  );
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const handleInputChange = (value, name) => {
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const setCoords = (lat, lng, location) => {
    if (lat && lng) {
      Geocode.fromLatLng(lat, lng).then((response) => {
        const address = response.results[0].formatted_address;
        setMarker({
          coords: { lat, lng },
          location: address,
        });
      });
    } else {
      Geocode.fromAddress(location).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setMarker({
            coords: { lat, lng },
            location,
          });
          setCenter({ lat, lng });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    // Whenever marker changes, send it back to the parent
    if (marker) {
      setCurrentLocation(marker);
      // Set the map center whenever a location is selected
      setCenter({ lat: marker.coords.lat, lng: marker.coords.lng });
    }
  }, [marker, setCurrentLocation]);

  const { radius } = inputs || {};
  useEffect(() => {
    // Whenever radius changes, send it back to the parent
    if (radius && setCurrentRadius) {
      setCurrentRadius(radius);
    }
  }, [radius, setCurrentRadius]);

  if (!isLoaded) return null;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      options={options}
      center={center}
      zoom={11}
      onClick={(e) => setCoords(e.latLng.lat(), e.latLng.lng())}
    >
      <Options>
        <Input
          bottomBorder
          label="Radius (m):"
          name="radius"
          value={inputs?.radius}
          type="number"
          onChange={handleInputChange}
        />
        <GooglePlacesAutocomplete
          debounce={500}
          selectProps={{
            value: {
              label: marker?.location || "",
              value: marker?.coords || "",
            },
            styles: {
              input: (provided) => ({
                ...provided,
                width: "420px",
                "@media (max-width: 500px)": {
                  width: "200px",
                },
              }),
            },
            onChange: ({ label }) => setCoords(null, null, label),
          }}
        />
      </Options>
      {marker?.coords && (
        <Marker icon={locationIcon} position={marker.coords} />
      )}
    </GoogleMap>
  );
};

export default Map;
