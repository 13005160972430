import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Wrapper, Counter, PriceInfo, Total, Continue } from "./styles";
import { useTranslation } from 'react-i18next';


const Employees = ({ setData, data, minEmployees }) => {
  const [count, setCount] = useState(minEmployees);
  const { plan, total } = data || {};
  const { pricePerUser, subscriptionId } = plan;
  const [loaded, setLoaded] = useState(false)
  const { t, i18n } = useTranslation('common');

  const setSelection = useCallback(
    (selection) => {
      if (selection < 1) return;
      setData((p) => ({ ...p, employees: +selection }));
    },
    [setData]
  );
  const setTotal = useCallback(
    (num) => {
      setData((p) => ({ ...p, total: +num }));
    },
    [setData]
  );

  useEffect(() => {
    if (count) {
      setSelection(count);

      // Check if we have a basic plan, allow only 10 maximum users.
      if (subscriptionId === "price_1IQ8dXHbUOKJeKdhWrp5Sv2b") {
        const max = 10;
        if (count >= max) {
          setSelection(max);
          setCount(max);
        }
      }
      if (count < minEmployees) {
        setCount(minEmployees)
      }
      // Calculate total price
      setTotal((count * pricePerUser).toFixed(2));
    }
  }, [count, pricePerUser, subscriptionId, setSelection, setTotal]);



  return (
    <Wrapper>
      <Counter>
        <span onClick={() => setCount((p) => (p > 1 ? p - 1 : 1))}>-</span>
        <input
          type="number"
          onChange={({ target }) =>
            setCount(target.value > 0 ? target.value : 1)
          }
          value={count}
        />
        <span onClick={() => setCount((p) => +p + 1)}>+</span>
      </Counter>
      <h4>
        * {t('subscription.totalAmount')}

      </h4>
      {subscriptionId === "price_1IQ8dXHbUOKJeKdhWrp5Sv2b" && <h4>* {t('subscription.maxAmount')}</h4>}
      <PriceInfo></PriceInfo>
      <Total>
        <h2>{total?.toFixed(2)}</h2>
        <span>SEK</span>
      </Total>
      <Continue>
        <Link
          to={{
            pathname: "/checkout",
            state: data,
          }}
        >
          {t('subscription.continue')} <span>{next}</span>
        </Link>

      </Continue>
    </Wrapper>
  );
};

const next = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.41"
    height="12"
    viewBox="0 0 7.41 12"
  >
    <path
      id="Path_5"
      data-name="Path 5"
      d="M8.59,16.59,13.17,12,8.59,7.41,10,6l6,6-6,6Z"
      transform="translate(-8.59 -6)"
      fill="#2774CC"
    />
  </svg>
);

export default Employees;
