import axios from "axios";
import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../constants";
import Form from "../UI/Form";
import Select from "../UI/Form/Select";
import Submit from "../UI/Form/Submit";
import Date from "../UI/Form/Date";
import Input from "../UI/Form/Input";

import Errors from "../UI/Form/Errors";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const EditTime = ({ event }) => {
    const [inputs, setInputs] = useState(null);
    const [errors, setErrors] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n } = useTranslation('common');


    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        setIsSuccess(false)
        const cookies = new Cookies(),
            token = cookies.get("token");
        axios({
            url: `${API_URL}/updateGeofenceEvent`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                ...inputs,
                event,
                userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone

            },
        })
            .then((res) => {
                const { success } = res.data;
                setIsLoading(false);
                if (success === false) {
                    return;
                }
                setIsSuccess(true)
            })
            .catch((err) => {
                setIsLoading(false);
                console.log("err", err);
            });
    }
    return (
        <>
            <h2> {t('timetables.editTimeEntry')}</h2>
            <Form onSubmit={handleSubmit}>
                <div style={{ marginBottom: "1.5rem" }}>
                    <Date
                        date={inputs?.date ? inputs.date : moment(event.timestamp).toDate()}
                        placeholder={t('timetables.date')}
                        onChange={(date) => setInputs((p) => ({ ...p, date: date }))}
                    />
                </div>
                <div style={{ marginBottom: "1.5rem" }}>
                    <Input
                        type="time"
                        default
                        placeholder={t('timetables.time')}
                        value={inputs?.time ? inputs.time : moment(event.timestamp).format('HH:mm')}
                        onChange={(time) => setInputs((p) => ({ ...p, time: time }))}
                    />
                </div>
                <Submit
                    loading={isLoading ? 1 : null}
                    title={t('timetables.confirm')}
                    size="medium"
                    margin="mt-4"
                />
            </Form>
            {isSuccess ? (
                <div style={{ marginTop: "1.5rem" }}>{t('timetables.success')}</div>
            ) : null}

        </>
    );
};

export default EditTime;
