import React, { useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  CurrentSubscriptionWrapper,
  Subscription,
  SubscriptionVisual,
  SubscriptionDownload,
  SubscriptionContent,
  SubscriptionData,
  SubscriptionActions,
  UpgradeSubscription,
  CancelSubscription,
  Error,
} from "./styles";
import illustration from "../../assets/media/subscription-card.svg";
import download from "../../assets/media/download.svg";
import { useHistory } from "react-router-dom";
import {
  useUserDispatch,
  useUserState,
} from "../../store/context/user-context";
import { API_URL } from "../../constants";
import { useTranslation } from 'react-i18next';

const CurrentSubscription = ({ subscription }) => {
  const [isError, setIsError] = useState(false);
  const { t, i18n } = useTranslation('common');

  const {
    status,
    btSubscriptionId,
    planName,
    users,
    subContractors,
    total,
    dueDate,
    expirationDate,
  } = subscription || {};
  const history = useHistory();
  const { data: user } = useUserState();
  const dispatch = useUserDispatch();

  const handleCancelSubscription = () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel your subscription?"
    );
    if (!confirmation) return;
    const companyId = user?.companies[0]?.id;
    setIsError(false);
    const cookies = new Cookies(),
      token = cookies.get("token");
    axios({
      url: `${API_URL}/cancel-subscription`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        subscriptionId: user?.companies[0]?.subscription.stripeId,
        companyId: companyId,
      },
    })
      .then((res) => {
        const { success, companies } = res.data;
        if (!success) {
          setIsError(true);
          return;
        }
        dispatch({
          type: "SET_COMPANIES",
          payload: companies,
        });
      })
      .catch(() => setIsError(true));
  };

  if (!subscription) return null;

  const countOfLocations = user?.companies
    ?.map(({ locations }) => locations?.length)
    .reduce((a, b) => a + b);

  return (
    <>
      {isError && (
        <Error>{t('subscription.error2')}</Error>
      )}
      <CurrentSubscriptionWrapper>
        <Subscription>
          <SubscriptionVisual>
            <img src={illustration} alt="" />
            <h2>{planName}</h2>
          </SubscriptionVisual>
          {/*           <SubscriptionDownload href="#!">
            <img src={download} alt="" />
            Download receipt (PDF)
          </SubscriptionDownload> */}
        </Subscription>
        <SubscriptionContent>
          <SubscriptionData>
            <span>{t('subscription.price')}</span>
            <h3>{total?.toFixed(2)} SEK</h3>
          </SubscriptionData>
          <SubscriptionData>
            <span>{t('subscription.users')}</span>
            <h3>{users}</h3>
          </SubscriptionData>
          <SubscriptionData>
            <span>{t('subscription.locations')}</span>
            <h3>{countOfLocations}</h3>
          </SubscriptionData>
          {subContractors != null && (
            <SubscriptionData>
              <span>{t('subscription.subcontractors')}</span>
              <h3>{subContractors}</h3>
            </SubscriptionData>
          )}
          <SubscriptionData>
            <span>{t('subscription.expiration')}</span>
            <h3>
              {expirationDate
                ? dayjs.unix(expirationDate?._seconds).format("DD.MM.YY")
                : "-"}
            </h3>
          </SubscriptionData>
          <SubscriptionData>
            <span>{t('subscription.dueDate')}</span>
            <h3>
              {dueDate && dayjs.unix(dueDate) > dayjs.unix(0) ? dayjs.unix(dueDate?._seconds).format("DD.MM.YY") : "-"}
            </h3>
          </SubscriptionData>
          {status && (
            <SubscriptionData>
              <span>{t('subscription.status')}</span>
              <h3>{status}</h3>
            </SubscriptionData>
          )}
          <SubscriptionActions>
            <UpgradeSubscription onClick={() => history.push("/cart")}>
              {t('subscription.upgrade')}
            </UpgradeSubscription>
            <CancelSubscription onClick={handleCancelSubscription}>
              {t('subscription.cancel')}
            </CancelSubscription>
          </SubscriptionActions>
        </SubscriptionContent>
      </CurrentSubscriptionWrapper>
    </>
  );
};

export default CurrentSubscription;
