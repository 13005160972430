import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useUserDispatch, useUserState } from "../store/context/user-context";
import { useHistory } from "react-router-dom";
import { getUser } from "../store/actions/user";

// HOC to handle protecting private routes

const withAuth = (Component) => (props) => {
  const [renderable, setRenderable] = useState(false);
  const user = useUserState();
  const dispatch = useUserDispatch();
  const history = useHistory();

  const { data, isFetched } = user;
  useEffect(() => {
    if (data) {
      setRenderable(true);
    } else {
      if (isFetched) {
        history.replace("/login");
      } else {
        const cookies = new Cookies(),
          token = cookies.get("token", { path: "/" });
        if (!token) {
          history.replace("/login");
        } else {
          // Get the user via token
          getUser()
            .then((res) => {
              const { success, user, companies } = res.data;
              if (!success) {
                const cookies = new Cookies();
                cookies.remove("token");
                history.replace("/login");
                return;
              }
              dispatch({
                type: "SET_USER",
                payload: { user, companies },
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
          setRenderable(false);
        }
      }
      setRenderable(false);
    }
  }, [user, data, dispatch, isFetched, history]);

  if (!renderable) return /* "Loading..."; */ null;
  return <Component {...props} />;
};
export default withAuth;
