import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import marker from "../../../assets/media/marker.svg";
import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_LIBRARIES } from "../../../constants";
import { useUserState } from "../../../store/context/user-context";
import CompanyCard from "../CompanyCard/Index";
import mapStyles from "./mapStyles";

const mapContainerStyle = {
  height: "calc(100vh - 83px)",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

const Map = ({ currentLocation }) => {
  const { isLoaded } = useLoadScript({
    libraries: GOOGLE_MAPS_LIBRARIES,
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const { data: user } = useUserState();
  const { companies } = user;
  const [selectedLocation, setSelectedLocation] = useState(
    currentLocation || null
  );
  const [center, setCenter] = useState({ lat: 50, lng: 16 });

  // Clear selected company when user companies change
  useEffect(() => {
    if (companies) {
      setSelectedLocation(null);
    }
  }, [companies]);

  // Whenever a location is selected, set it to the center of the map
  useEffect(() => {
    if (selectedLocation) {
      const { latitude: lat, longitude: lng } = selectedLocation;
      setCenter({ lat, lng });
    }
  }, [selectedLocation]);

  // Set prop currentLocation to local state
  useEffect(() => {
    if (currentLocation) setSelectedLocation(currentLocation);
  }, [currentLocation]);

  // Center the map to the first location found in companies
  useEffect(() => {
    if (companies?.length > 0) {
      const { locations } =
        companies.find(({ locations }) => locations?.length > 0) || [];
      if (locations?.length > 0) {
        const { latitude: lat, longitude: lng } = locations[0];
        setCenter({ lat, lng });
      }
    }
  }, [companies]);

  if (!isLoaded) return null;
  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={10}
      options={options}
      center={center && center}
    >
      {user?.companies.map((company) => {
        const { locations } = company;
        if (locations?.length <= 0) return null;
        return locations.map((location) => {
          const { id, latitude, longitude } = location;
          return (
            <Marker
              icon={marker}
              key={id}
              position={{
                lat: latitude,
                lng: longitude,
              }}
              onClick={() => {
                setSelectedLocation(location);
              }}
            />
          );
        });
      })}
      {selectedLocation && (
        <InfoWindow
          position={{
            lat: selectedLocation?.latitude,
            lng: selectedLocation?.longitude,
          }}
          onCloseClick={() => setSelectedLocation(null)}
          options={{ minWidth: 300 }}
        >
          <CompanyCard selectedLocation={selectedLocation} />
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default Map;
