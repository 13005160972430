import styled from "styled-components";

export const DetailInnerWrapper = styled.div`
  @media only screen and (min-width: 768px) {
    flex: 0 0 50%;
  }
`;

export const Detail = styled.div`
  display: grid;
  grid-template: "header header";
  margin-bottom: 2rem;
  h4,
  span {
    color: #5a6978;
    font-family: "Euclid-Regular", sans-serif;
  }
  h4 {
    font-family: "Euclid-Bold", sans-serif;
    font-weight: 700;
  }
  span {
    font-weight: 400;
    margin-left: 0.5rem;
  }
  @media only screen and (min-width: 768px) {
    grid-template-columns: 200px 200px;
    h4,
    span,
    label {
      font-size: 18px;
    }
  }
`;

export const Options = styled.div`
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-55%);
  @media only screen and (max-width: 767px) {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
  }
`;
