import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 5rem 1rem 2rem 1rem;
  margin: auto;
  min-height: calc(100vh - 193px);
  p {
    color: #5a6978;
    line-height: 1.6;
  }
  @media only screen and (min-width: 768px) {
    max-width: 600px;
    margin-top: 2rem;
    min-height: calc(100vh - 209px);
  }
  @media only screen and (min-width: 1200px) {
    max-width: 900px;
    p {
      font-size: 18px;
      line-height: 35px;
    }
  }
`;

export const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 24px;
  font-weight: 700;
  font-family: "Euclid-Bold", sans-serif;
  line-height: 34px;
  color: #343f4b;
  margin-top: 3rem;
`;


export const BackgroudWrapper = styled.div`
  background: linear-gradient(#2EB6BA, #3167B2);
  min-height: calc(100vh + 50px);
  width: 100%;
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogoWrapper = styled.div`
  display: block;
  text-align: center;
  margin-bottom: 40px;
  img {
    width: 70px;
  }
  @media print {
    .test {
      color:#fff;
    }
  }
`
export const LogoTitle = styled.h2`
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin-top: 15px;
  font-size: 1.4rem;
  margin-bottom: 2em;
`

export const Logo = styled.img`
  width: 80px;
`
export const SignInText = styled.p`
  color: #fff !important;
  margin-bottom: 40px;
`
export const SmallText = styled.div`
  color: #fff !important;
  margin-bottom: 40px;
  font-size: 14px !important;
  text-decoration: underline;
  cursor: pointer;
`

export const FlexWrapper = styled.div`

  padding: 0 20px;
  p {
    color: #5a6978;
    line-height: 1.6;
  }
  @media only screen and (min-width: 768px) {
    max-width: 600px;
    margin-top: 2rem;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 900px;
    p {
      font-size: 18px;
      line-height: 35px;
    }
  }
`;