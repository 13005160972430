import React from "react";
import {
  ComparisonOuter,
  ComparisonInner,
  Wrapper,
  ComparisonHeader,
  ComparisonHeaderItem,
  ComparisonBody,
  ComparisonRow,
  ComparisonRowItem,
} from "./styles";
import { useTranslation } from 'react-i18next';

const Comparison = ({ plans }) => {
  const { t, i18n } = useTranslation('common');

  const featureLength = plans.map(({ features }) => features.length)[0];

  return (
    <Wrapper>
      <h2>{t('subscription.comparePlans')}</h2>
      <ComparisonOuter>
        <ComparisonInner>
          <ComparisonHeader>
            <ComparisonHeaderItem flex="0.4"></ComparisonHeaderItem>
            {plans.map((plan) => {
              let { name } = plan;
              if (name === "JobPals Construction") {
                name = t('subscription.constTitle')
              }
              return (
                <ComparisonHeaderItem flex="0.3">
                  {name.split(" ")[1]}
                </ComparisonHeaderItem>
              );
            })}
          </ComparisonHeader>
          <ComparisonBody>
            {[...Array(featureLength)].map((e, i) => {
              const name = plans[0].features[i]?.label;
              return (
                <ComparisonRow>
                  <ComparisonRowItem flex="0.4">{t(`subscription.${name}`)}</ComparisonRowItem>
                  {[...Array(plans.length)].map((e, planIndex) => {
                    let { value } = plans[planIndex]?.features[i] || {};
                    if (value === "Unlimited") {
                      value = `${t('subscription.unlimited')}`
                    }
                    if (value === "Up to 10") {
                      value = `${t('subscription.upTo')}`
                    }
                    return (
                      <ComparisonRowItem flex="0.3">
                        {typeof value === "string"
                          ? value
                          : value
                            ? checkmark
                            : close}
                      </ComparisonRowItem>
                    );
                  })}
                </ComparisonRow>
              );
            })}
          </ComparisonBody>
        </ComparisonInner>
      </ComparisonOuter>
    </Wrapper>
  );
};

const checkmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z" fill="#14C431" />
  </svg>
);

const close = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M0,0H24V24H0Z" fill="none" />
    <path
      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
      fill="#db6a6a"
    />
  </svg>
);

export default Comparison;
