import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import close from "../../assets/media/close.png";
import { useHistory, useLocation } from "react-router-dom";
import { Wrapper, Title, Close, FormWrapper } from "../CompanyInfo/styles";
import { API_URL } from "../../constants";
import Cookies from "universal-cookie";
import axios from "axios";

import Footer from "../Auth/Footer";
import Select from "../UI/Form/Select";
import Table from "../UI/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableHeaderItem from "../UI/Table/TableHeaderItem";
import TableRow from "../UI/Table/TableRow";
import Date from "../UI/Form/Date";
import Submit from "../UI/Form/Submit";

import TableRowItem from "../UI/Table/TableRowItem";
import { Inner } from "../Users/styles";
import Modal from "../UI/Modal";
import SideModal from "../UI/Modal/SideModal";
import useModal from "../UI/Modal/useModal";
import useSideModal from "../UI/Modal/useSideModal";
import EditTime from "./editTime";


const Timetables = () => {
    const history = useHistory();
    const location = useLocation();
    const { location: companyLocation } = location?.state || {};
    //const { id, name, orgNumber, address, adminEmail, constructionSite } = selectedLocation;
    // const { isShowingSide, toggleSide } = useSideModal();
    const { t, i18n } = useTranslation('common');
    const [isFetched, setIsFetched] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [employees, setEmployees] = useState(null);
    const [employeeOptions, setEmployeeOptions] = useState(null);
    const [inputs, setInputs] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)

    const { isShowingSide, toggleSide } = useSideModal();

    useEffect(() => {
        // Check if ID exists, if not, notify user
        if (!companyLocation?.id) {
            setIsFetched(true);
            return;
        }
        const cookies = new Cookies(),
            token = cookies.get("token");
        axios({
            url: `${API_URL}/companyUsers`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: { id: companyLocation?.id },
        })
            .then((res) => {

                setIsFetched(true);
                const { success, users } = res.data;
                if (!success) {
                    setIsSuccess(false);
                    return;
                }
                setIsSuccess(true);
                setEmployees(users);
            })
            .catch((err) => {
                setIsFetched(true);
                console.log("err", err);
            });
    }, [companyLocation]);

    useEffect(() => {
        if (employees) {
            const options = employees.map((emp) => {
                return {
                    label: `${emp?.firstName} ${emp?.lastName}`,
                    value: emp?.id,
                };
            });
            setEmployeeOptions(options);
        }
    }, [employees]);

    const handleSubmit = (e) => {
        setEvents([])
        e.preventDefault();
        setIsLoading(true);
        const cookies = new Cookies(),
            token = cookies.get("token");
        axios({
            url: `${API_URL}/geofenceEvents`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                userId: inputs?.employees?.value,
                locationId: companyLocation?.id,
                startDate: inputs?.startDate,
                endDate: inputs?.endDate,
                userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone

            }
        })
            .then((res) => {
                setIsLoading(false)
                const { success, events } = res.data;
                if (!success || events.length === 0) {
                    setIsSuccess(false);
                    return;
                }
                setIsSuccess(true)
                setEvents(events)

            })
            .catch((err) => {
                setIsLoading(false)
                setIsSuccess(false);
                console.log("err", err);
            });
    }

    const handleEdit = (event) => {
        setSelectedEvent(event)
        toggleSide()
    }

    return (
        <>
            <Wrapper style={{ minHeight: "calc(100vh - 130px)" }}>
                <Close onClick={() => history.push("/")}>
                    <img src={close} alt="Close" />
                </Close>
                <Title>{t('location.timetables')}</Title>
                <form onSubmit={handleSubmit} style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                    <div style={{ marginBottom: "1.5rem", width: "221px", marginRight: "20px" }}>

                        <Select
                            placeholder={t('timetables.selectUser')}
                            name="name"
                            isMulti={false}
                            options={employeeOptions}
                            value={inputs?.employees}
                            onChange={(val) => setInputs((p) => ({ ...p, employees: val }))}
                        />
                    </div>
                    <div style={{ marginBottom: "1.5rem", marginRight: "20px" }}>
                        <Date
                            date={inputs?.startDate}
                            placeholder={t('timetables.startDate')}
                            onChange={(date) => setInputs((p) => ({ ...p, startDate: date }))}
                        />
                    </div>
                    <div style={{ marginBottom: "1.5rem" }}>
                        <Date
                            date={inputs?.endDate}
                            disabled={false}
                            minDate={inputs?.startDate}
                            placeholder={t('timetables.endDate')}
                            onChange={(date) => setInputs((p) => ({ ...p, endDate: date }))}
                        />
                    </div>
                    <div style={{ marginBottom: "1.5rem", width: "100%" }}>
                        <Submit
                            loading={isLoading && !isFetched ? 1 : null}
                            title={t('timetables.search')}
                            size="medium"
                            margin="mt-0"
                        />
                    </div>
                </form>

                <Inner>
                    <Table>
                        <TableHeader>
                            <TableHeaderItem flex="0.3">{t('timetables.time')}</TableHeaderItem>
                            <TableHeaderItem flex="0.3">{t('timetables.locationName')}</TableHeaderItem>
                            <TableHeaderItem flex="0.3">{t('timetables.event')}</TableHeaderItem>
                            <TableHeaderItem flex="0.2">{t('timetables.edit')}</TableHeaderItem>
                        </TableHeader>
                        {!isSuccess && events?.length === 0 ? (
                            <div style={{ marginTop: "1.5rem", width: "100%", textAlign: "center" }}>
                                {t('timetables.noResults')}
                            </div>
                        ) : (
                            events.map((event) => {
                                const { documentID, action, geofence, eventTime, timestamp } = event || {};
                                return (
                                    <TableRow key={documentID}>
                                        <TableRowItem flex="0.3">
                                            <p>{eventTime}</p>
                                        </TableRowItem>
                                        <TableRowItem flex="0.3">
                                            <p>{geofence}</p>
                                        </TableRowItem>
                                        <TableRowItem flex="0.3">
                                            <p>{action}</p>
                                        </TableRowItem>
                                        <TableRowItem flex="0.2" align="flex-start">
                                            <button value={t('timetables.edit')} onClick={() => handleEdit(event)}>{t('timetables.edit')}</button>
                                        </TableRowItem>
                                    </TableRow>
                                );
                            })

                        )}
                    </Table>
                </Inner>
            </Wrapper>
            <SideModal isShowingSide={isShowingSide} hide={toggleSide}>
                <EditTime event={selectedEvent} />

            </SideModal>
            <Footer />
        </>
    );
};

export default Timetables;
