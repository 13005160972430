import React from "react";
import { TableRowItemWrapper } from "./styles";

const TableRowItem = ({ children, flex, align }) => {
  return (
    <TableRowItemWrapper flex={flex} align={align}>
      {children}
    </TableRowItemWrapper>
  );
};

export default TableRowItem;
