import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../store/context/app-context";
import { useUserState } from "../../../store/context/user-context";
import { OnlyMobile } from "../../../utils/globalStyles";
import Modal from "../../UI/Modal";
import useModal from "../../UI/Modal/useModal";
import RegistrateCompany from "../RegistrateCompany";
import DrawerItem from "./DrawerItem";
import { useTranslation } from 'react-i18next';

import {
  DrawerCollection,
  LinkWrapper,
  Outer,
  SideDrawerHeader,
  Wrapper,
} from "./Styles";

const SideDrawer = (props) => {
  const { isOpen, setIsOpen, setCurrentLocation } = props;
  const { isShowing, toggle } = useModal();
  const { data: user } = useUserState();
  const { companies } = user;
  const appDispatch = useAppDispatch();
  const { t, i18n } = useTranslation('common');
  const handleLogout = () => {
    appDispatch({
      type: "SET_LOGOUT_MODAL",
      payload: true,
    });
  };

  return (
    <Outer isOpen={isOpen}>
      <Wrapper>
        <SideDrawerHeader>
          <h3>{t('sideDrawer.yourCompanies')}</h3>
        </SideDrawerHeader>
        <DrawerCollection>
          {companies.map((company) => {
            const { id, companyName, locations } = company;
            return (
              <DrawerItem
                key={id}
                id={id}
                title={companyName}
                locations={locations}
                setCurrentLocation={setCurrentLocation}
                setIsNavOpen={setIsOpen}
              />
            );
          })}
        </DrawerCollection>
        <SideDrawerHeader>
          <h3>{t('sideDrawer.navigation')}</h3>
        </SideDrawerHeader>
        <LinkWrapper>
          <Link to="/users">{t('sideDrawer.users')}</Link>
          <Link to="/subscription">{t('sideDrawer.subscription')}</Link>
          <Link to="/cart">{t('sideDrawer.payment')}</Link>
          <Link to="/company-info">{t('sideDrawer.companyInfo')}</Link>

          <OnlyMobile>
            <Link to="#!" onClick={handleLogout}>
              {t('sideDrawer.logOut')}
            </Link>
          </OnlyMobile>
        </LinkWrapper>
      </Wrapper>
      <Modal isShowing={isShowing} hide={toggle}>
        <RegistrateCompany toggleModal={toggle} />
      </Modal>
    </Outer>
  );
};

export default SideDrawer;
