import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem;
  max-width: 900px;
  margin: 0 auto;
  h1 {
    font-size: 40px;
    font-weight: 700;
    color: #000;
    margin: 1rem 0;
    line-height: 1.4;
  }
  @media (min-width: 992px) {
    min-height: calc(100vh - 100px);
    padding-top: 4rem;
    max-width: 1200px;
  }
`;
export const Cross = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  img {
    width: 44px;
    height: 44px;
  }
  @media (min-width: 992px) {
    top: 6rem;
    right: 6rem;
  }
`;

export const Inner = styled.div`
  margin: 2rem 0;
`;

export const UsersHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 1.5rem;
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
  a {
    white-space: nowrap;
    color: #2774CC;
    padding: 1rem;
    display: flex;
    transition: 0.25s all ease;
    border-radius: 4px;
    height: max-content;
    width: max-content;
    &:hover {
      background: #eaeaea;
    }
  }
`;

export const Grouping = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const Group = styled.button`
  background: none;
  border: 1px solid #2774CC;
  color: #2774CC;
  border-radius: 999px;
  padding: 0.75rem 1.25rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: 0.25s all ease;
  font-family: "Euclid-Regular", sans-serif;
  ${({ active }) =>
    active &&
    css`
      background: #2774CC;
      color: #fff;
      cursor: not-allowed;
    `}
`;

export const Error = styled.div`
  color: red;
  line-height: 1.4;
`;

export const CurrentSubscriptionWrapper = styled.div`
  margin-top: 6rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (min-width: 992px) {
    display: inline-flex;
    flex-direction: row;
  }
`;

export const Subscription = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
`;

export const SubscriptionVisual = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  img {
    width: 100%;
    border-radius: 4px;
  }
  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 25px;
    padding: 0 1rem;
    text-align: center;
    line-height: 1.4;
  }
`;

export const SubscriptionDownload = styled.a`
  background: none;
  padding: 0;
  border: none;
  display: flex;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  font-size: 16px;
  color: #737373;
  transition: 0.25s all ease;
  cursor: pointer;
  text-decoration: none;
  img {
    margin-right: 1rem;
  }
  &:hover {
    color: #313131;
  }
`;

export const SubscriptionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 0.6;
  margin-top: 1.5rem;
  @media (min-width: 992px) {
    margin-left: 2rem;
    margin-top: 0;
  }
`;

export const SubscriptionData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  height: max-content;
  margin: 0 1rem 1rem 0;
  span {
    display: flex;
    width: 100%;
    font-size: 16px;
    color: #979797;
    margin-bottom: 0.25rem;
    white-space: nowrap;
  }
  h3 {
    color: #2c2c2c;
    font-weight: 700;
    white-space: nowrap;
  }
`;

export const SubscriptionActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  padding: 1.5rem 0;
  border-top: 1px solid #dadada;
`;

export const UpgradeSubscription = styled.button`
  border: none;
  background: #2774CC;
  font-family: "Euclid-Regular";
  display: flex;
  width: max-content;
  border-radius: 4px;
  color: #fff;
  padding: 1rem;
  margin: 0.5rem;
  transition: 0.25s all ease;
  &:hover {
    background: #1f55cd;
  }
`;
export const CancelSubscription = styled(UpgradeSubscription)`
  background: #fff;
  color: #737373;
  &:hover {
    background: #fff;
    color: #000;
  }
`;

export const OrderHistoryWrapper = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 6rem;
  @media (min-width: 992px) {
    margin-bottom: 10rem;
  }
`;

export const DownloadReceipt = styled.a`
  padding: 0.35rem 0.5rem;
  transition: 0.25s all ease;
  border-radius: 4px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  &:hover {
    background: #eaeaea;
  }
`;
