import React from "react";
import AsyncCreatableSelect from "react-select/async-creatable";

const Creatable = ({
  promiseOptions,
  placeholder,
  name,
  value,
  onChange,
  onInputChange,
}) => {
  return (
    <AsyncCreatableSelect
      cacheOptions
      //defaultOptions
      loadOptions={promiseOptions}
      styles={customStyles}
      placeholder={placeholder}
      name={name}
      value={value || null}
      createOptionPosition="first"
      onChange={onChange}
      onInputChange={onInputChange}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

const customStyles = {
  container: (styles) => ({
    ...styles,
    width: "100%",
  }),
  control: (styles) => ({
    ...styles,
    padding: 3,
    borderColor: "#8492A6",
  }),
  placeholder: (styles) => ({ ...styles, color: "#47525E" }),
};

export default Creatable;
