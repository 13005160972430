import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  button {
    padding: 0.75rem;
    border-radius: 5px;
    border-color: transparent;
    background-color: #2774CC;
    color: #fff;
    font-family: "Euclid-Regular", sans-serif;
    font-size: 16px;
    ${(props) =>
      props.cancel &&
      css`
        background-color: transparent;
        color: #929eb1;
        border: 1px solid #929eb1;
      `};
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      button {
        background: #ccc;
        cursor: not-allowed;
        border: none;
        color: #f9f9f9;
      }
    `}
  ${(props) =>
    props.transparent &&
    css`
      button {
        background-color: transparent;
        color: #2774CC;
        border: none;
        padding: 0;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }
    `}
  ${({ size }) => {
    if (size === "small") {
      return css`
        width: 100%;
        button {
          padding: 0.75rem 2.5rem;
        }
      `;
    }
    if (size === "medium") {
      return css`
        margin: 0 1rem;
        button {
          width: 100%;
          padding: 0.75rem 3rem;
        }
      `;
    }
    if (size === "large") {
      return css`
        margin: 0 1rem;
        button {
          width: 192px;
        }
      `;
    }
  }}
  @media only screen and (min-width: 560px) {
    button {
      font-size: 18px;
    }
  }
`;
