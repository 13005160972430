import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 4rem auto;
  h4 {
    font-size: 13px;
    color: #b9b9b9;
    margin-top: 8px;
  }
`;
export const Counter = styled.div`
  background: #fefdfd;
  border: 1px solid #ebe9e9;
  padding: 0.5rem;
  padding-bottom: 0.8rem;
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  width: 280px;
  input {
    appearance: none;
    background: none;
    border: none;
    font-size: 41px;
    font-weight: 700;
    font-family: "Euclid-Bold";
    max-width: 100px;
    -moz-appearance: textfield;
    text-align: center;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  span {
    display: flex;
    font-size: 70px;
    align-items: center;
    justify-content: center;
    color: #cacaca;
    cursor: pointer;
    padding: 0 1.5rem;
    font-weight: 700;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: 0.25s all ease;
    &:hover {
      color: #adadad;
    }
  }
`;

export const PriceInfo = styled.div`
  width: 280px;
  margin-top: 1rem;
  padding-bottom: 3rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #b0b0b0;
  div {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0;
    span {
      color: #b0b0b0;
      font-size: 20px;
    }
  }
`;

export const Total = styled.div`
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  h2 {
    color: #000;
    font-size: 77px;
    font-weight: 700;
    line-height: 0.8;
    margin-right: 0.25rem;
  }
  span {
    color: #cacaca;
    font-size: 20px;
  }
`;

export const Continue = styled.div`
  margin-top: 3rem;
  text-align: center;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 1rem;
    margin: 0 auto;
    color: #2774CC;
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
    span {
      margin: 0 1rem;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;
