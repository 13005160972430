import React from "react";
import Footer from "../Auth/Footer";
import Header from "../Main/Header";
import { Wrapper, Title } from "../Policy/styles";

const Contact = () => {
  return (
    <>
      <Header variant="secondary" title="Contact us" />
      <Wrapper>
        <Title>Content</Title>
        <p>Contact us</p>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Contact;
