import styled from "styled-components";

export const Wrapper = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  transition: 0.25s all ease;
  background: #fff;
  border-radius: 50%;
  &:hover {
    border: 1px solid #2455c2;
  }
  img {
    width: 14px;
    height: 14px;
  }
`;
