import React, { useRef, useState } from "react";
import Cookies from "universal-cookie";
import { HeaderWrapper, Burger, User, UserDropdown, LanguageSelect } from "./Styles";
import logo from "../../../assets/media/logo.png";
import { Link } from "react-router-dom";
import {
  useUserDispatch,
  useUserState,
} from "../../../store/context/user-context";
import { OnlyDesktop, OnlyMobile } from "../../../utils/globalStyles";
import useOnClickOutside from "../../../utils/useOnClickOutside";
import {
  useAppDispatch,
  useAppState,
} from "../../../store/context/app-context";
import Modal from "../../UI/Modal";
import Button from "../../UI/Button";
import uk from "../../../assets/media/united-kingdom.svg";
import se from "../../../assets/media/sweden.svg";

import { useTranslation } from 'react-i18next';

const Header = (props) => {
  const { toggleMenu } = props || {};
  const { data: user } = useUserState();

  const { IS_LOGOUT_MODAL_OPEN } = useAppState();
  const dispatch = useUserDispatch();
  const appDispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const userRef = useRef();
  useOnClickOutside(userRef, () => setIsOpen(false));
  const { t, i18n } = useTranslation('common');

  const handleShowLogoutModal = (val) => {
    appDispatch({
      type: "SET_LOGOUT_MODAL",
      payload: val,
    });
  };

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove("token");
    dispatch({ type: "SET_USER", payload: null });
    appDispatch({
      type: "SET_LOGOUT_MODAL",
      payload: false,
    });
  };



  return (
    <>
      <HeaderWrapper variant={props.variant}>
        <Link to="/">
          <img src={logo} alt="JobPals" />
          {props.applicable && <span>Admin</span>}
        </Link>
        <LanguageSelect>
          <p>{t('general.language')} :</p>
          <button onClick={() => i18n.changeLanguage('en')}>
            <img src={uk} />
          </button>
          <button onClick={() => i18n.changeLanguage('se')}>
            <img src={se} />
          </button>

        </LanguageSelect>
        {props.title && <h2>{props.title}</h2>}
        {props.applicable && (
          <OnlyMobile>
            <Burger>
              <span onClick={toggleMenu ? toggleMenu : null}>{t('sideDrawer.menu')}</span>
            </Burger>
          </OnlyMobile>
        )}

        {user && (
          <User ref={userRef}>
            <button type="button" onClick={() => setIsOpen((p) => !p)}>
              {user?.user?.firstName && user?.user?.lastName
                ? `${user?.user?.firstName} ${user?.user?.lastName}`
                : user?.user?.email
                  ? user?.user?.email
                  : ""}{" "}
              {arrowDown}
            </button>
            <UserDropdown isOpen={isOpen}>
              <button
                type="button"
                onClick={() => handleShowLogoutModal(!IS_LOGOUT_MODAL_OPEN)}
              >
                {t('sideDrawer.logOut')}
              </button>
            </UserDropdown>
          </User>
        )}
      </HeaderWrapper>
      {IS_LOGOUT_MODAL_OPEN && (
        <Modal
          isShowing={IS_LOGOUT_MODAL_OPEN}
          hide={() => handleShowLogoutModal(false)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
            }}
          >
            <h1
              style={{
                fontSize: "40px",
                fontWeight: "700",
                paddingLeft: "16px",
                marginBottom: "2rem",
                lineHeight: "1.4",
              }}
            >
              {t('sideDrawer.confirm')}
            </h1>
            <div
              style={{
                display: "inline-flex",
              }}
            >
              <Button
                title={t('sideDrawer.cancel')}
                cancel
                size="medium"
                onClick={() => handleShowLogoutModal(!IS_LOGOUT_MODAL_OPEN)}
              />
              <Button title={t('sideDrawer.logOut')} size="medium" onClick={handleLogout} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const arrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
  </svg>
);

export default Header;
